import React from "react";
import { Link } from "react-router-dom";
import "./ResidentHealthDataView.css";
import Dashboard from "../Dashboard/Dashboard";

export default function ResidentHealthDataView() {
  return (
    <>
      <Dashboard />
      <div className="main-content rhdvata-main">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="row">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    John Doe
                  </li>
                </ol>
              </nav>
              <div className="col-lg-12">
                <Link>
                  <div className="rhdv-box">
                    <div>
                      <h3>Health Summary</h3>
                    </div>
                    <div>
                      <img src="/images/arrow-right-blue.svg" alt="" />
                    </div>
                  </div>
                </Link>
                <Link>
                  <div className="rhdv-box">
                    <div>
                      <h3>Activity Tracker</h3>
                    </div>
                    <div>
                      <img src="/images/arrow-right-blue.svg" alt="" />
                    </div>
                  </div>
                </Link>
                <Link to='/admin/nurse/alerts'>
                  <div className="rhdv-box">
                    <div>
                      <h3>Alerts</h3>
                    </div>
                    <div className="d-flex gap-2 gap-lg-3 align-items-center">
                      <div className="attention-required-box">
                        <div className="d-flex gap-1 align-items-center">
                          <img src="/images/warning.svg" alt="" />
                          <p>Attention Required</p>
                        </div>
                      </div>
                      <img src="/images/arrow-right-blue.svg" alt="" />
                    </div>
                  </div>
                </Link>
                <Link>
                  <div className="rhdv-box">
                    <div>
                      <h3>Diet & Nutrition</h3>
                    </div>
                    <div>
                      <img src="/images/arrow-right-blue.svg" alt="" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
