import React from "react";
import { Link } from "react-router-dom";
import "./Alerts.css";
import Dashboard from "../Dashboard/Dashboard";

export default function Alerts() {
  return (
    <>
      <Dashboard />
      <div className="main-content alerts-main">
        <div className="container-fluid p-0 m-0">
          <div className="home">
            <div className="breadcrumb-box">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="#">John Doe</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <Link>Alerts</Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="alert-box">
                  <h1>Alerts</h1>
                  <div className="detected-box d-grid gap-4 d-sm-flex align-items-center justify-content-between">
                    <div className="d-flex gap-2 align-items-start">
                      <img src="/images/alerts-icon.svg" alt="" />
                      <div>
                        <h5>
                          Fall Detected <span>1 min ago</span>
                        </h5>
                        <p>Don’t panic we are trying to help you</p>
                      </div>
                    </div>
                    <div className="mb-3 mb-sm-0">
                      <Link to="#">Book Ambulance</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-lg-4 col-md-6">
                <div className="alerts-card">
                  <h2 className="av"><span><img src="/images/green-border.svg" alt="" /></span>Average</h2>
                  <div className="">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <div className="accordion-header d-flex accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne">
                        <img src="/images/oversleep.svg" alt=""/>
                        <div className="d-flex justify-content-between ms-2 align-items-center w-100">
                            <div>
                            <h4>
                            Oversleep
                            </h4>
                            <p>10hr of sleep</p>
                            </div>
                            <h5 className="me-2">+2hrs</h5>
                        </div>
                        </div>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body pt-0">
                            <div className="alerts-data">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Target</h5>
                                    <h6>8 hrs</h6>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h5>Total hrs slept</h5>
                                    <h6><span>10 hrs</span></h6>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div className="accordion-header d-flex accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo">
                        <img src="/images/good-walk.svg" alt=""/>
                        <div className="d-flex justify-content-between ms-2 align-items-center w-100">
                            <div>
                            <h4>
                            Good Walking Session
                            </h4>
                            <p>Waking Session</p>
                            </div>
                            <h5 className="me-2">+2hrs</h5>
                        </div>
                        </div>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body pt-0">
                            <div className="alerts-data">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Target</h5>
                                    <h6>8 hrs</h6>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h5>Total hrs slept</h5>
                                    <h6><span>10 hrs</span></h6>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="alerts-card warning-br">
                  <h2 className="av"><span><img src="/images/yellow-br.svg" alt="" /></span>Warning</h2>
                  <div className="">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <div className="accordion-header d-flex accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree">
                        <img src="/images/heart.svg" alt=""/>
                        <div className="d-flex justify-content-between ms-2 align-items-center w-100">
                            <div>
                            <h4>
                            Heart
                            </h4>
                            <p>Heart Rate Abnormality Detected</p>
                            </div>
                            <h5 className="me-2 d-none">+2hrs</h5>
                        </div>
                        </div>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body pt-0">
                            <div className="alerts-data">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Target</h5>
                                    <h6>8 hrs</h6>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h5>Total hrs slept</h5>
                                    <h6><span>10 hrs</span></h6>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div className="accordion-header d-flex accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour">
                        <img src="/images/abnormal.svg" alt=""/>
                        <div className="d-flex justify-content-between ms-2 align-items-center w-100">
                            <div>
                            <h4>
                            Abnormal Breathing Rate 
                            </h4>
                            <p>Abnormal Breathing Rate</p>
                            </div>
                            <h5 className="me-2 d-none">+2hrs</h5>
                        </div>
                        </div>
                        <div
                          id="flush-collapseFour"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body pt-0">
                            <div className="alerts-data">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Target</h5>
                                    <h6>8 hrs</h6>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h5>Total hrs slept</h5>
                                    <h6><span>10 hrs</span></h6>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="alerts-card critical-br">
                  <h2 className="av"><span><img src="/images/red-br.svg" alt="" /></span>Critical</h2>
                  <div className="">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <div className="accordion-header d-flex accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive"
                            aria-expanded="false"
                            aria-controls="flush-collapseFive">
                        <img src="/images/heart-bp.svg" alt=""/>
                        <div className="d-flex justify-content-between ms-2 align-items-center w-100">
                            <div>
                            <h4>
                            Abnormal Blood Pressure
                            </h4>
                            <p>9hr of sleep</p>
                            </div>
                            <h5 className="me-2 d-none">+2hrs</h5>
                        </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body pt-0">
                            <div className="alerts-data">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Target</h5>
                                    <h6>8 hrs</h6>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h5>Total hrs slept</h5>
                                    <h6><span>10 hrs</span></h6>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <div className="accordion-header d-flex accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix"
                            aria-expanded="false"
                            aria-controls="flush-collapseSix">
                        <img src="/images/heart-bp.svg" alt=""/>
                        <div className="d-flex justify-content-between ms-2 align-items-center w-100">
                            <div>
                            <h4>
                            Abnormal Blood Pressure
                            </h4>
                            <p>9hr of sleep</p>
                            </div>
                            <h5 className="me-2 d-none">+2hrs</h5>
                        </div>
                        </div>
                        <div
                          id="flush-collapseSix"
                          class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body pt-0">
                            <div className="alerts-data">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5>Target</h5>
                                    <h6>8 hrs</h6>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <h5>Total hrs slept</h5>
                                    <h6><span>10 hrs</span></h6>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
