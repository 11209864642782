import React,{useState} from 'react';
import './Otp.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";
import Loading from '../../Admin/Loader/Loading';
import Service from '../../../Service/Team/Famaly/Services';

export default function FamilyOTP() {
    const navigate = useNavigate();
    const [error,serError] = useState("");
    const [loading,setLoading] = useState(false);
    const [arr,setArr] = useState([]);
    const [otp,setOtp] = useState("");
    const [otp1,setOtp1] = useState("");
    const [otp2,setOtp2] = useState("");
    const [otp3,setOtp3] = useState("");
    const [otp4,setOtp4] = useState("");
    const [otp5,setOtp5] = useState("");
    let email = localStorage.getItem('memberEmail');
    var getOtp = '';
    const familyOTP =async (e)=>{
        e.preventDefault();
        getOtp = otp+otp1+otp2+otp3+otp4+otp5;
        if(validate()){
            setLoading(true)
            const item = {otp:getOtp,email:email}
            const apicall = await Service.FamilyOtp(item);
            if(apicall.state === true)
            {
                apicall.result.json().then((resp)=>{
                    console.log(resp);
                    if(resp.status === false)
                    {
                        serError(resp.message)
                        setLoading(false)

                    }
                    else if(resp.status === true)
                    {
                        console.log(resp.details);
                        localStorage.setItem("familyDetails",JSON.stringify(resp.details))
                        navigate('/family/home')
                        setLoading(false)

                    }
                   
                })
            }else{
                apicall.result.json().then((resp)=>{
                    console.log(resp)
                    serError(resp.message)
                    setLoading(false)
                })
            }
        }
    }

    const validate = ()=>{
        let result = true;
        if(!getOtp || getOtp.length < 6)
        {
            serError("OTP is required")
            return result = false;
        }
        return result;
        
    }
  return (
    <div className="sign-up otp-box">
        <div className='container-lg'>
        <div className="row">
        <div className="col-md-6">
                    {/* <div className="sign-up-image">
                        <div className="sign-up-overlay">
                            <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div> */}
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
            <div className="col-md-6">
                <div className="verify-otp-section">
                    <div className="sign-up-wellcome">
                        <img src={Logo}  alt=""/>
                        <h3>Verify OTP</h3>
                        <p>Enter the verification code sent to your email id</p>
                    </div>
                    <div className="verify-otp">
                        <form action="" onSubmit={familyOTP}>
                            <div className="input-field">
                                <label for="">Verify OTP*</label>
                                <small className='text-danger'>{error}</small>
                                <div className='opt-inputs mt-3'>
                                <input type="text" placeholder="."  onChange={(e)=>setOtp(e.target.value)}/>
                                <input type="text" placeholder="."  onChange={(e)=>setOtp1(e.target.value)}/>
                                <input type="text" placeholder="."  onChange={(e)=>setOtp2(e.target.value)}/>
                                <input type="text" placeholder="."  onChange={(e)=>setOtp3(e.target.value)}/>
                                <input type="text" placeholder="."  onChange={(e)=>setOtp4(e.target.value)}/>
                                <input type="text" placeholder="."  onChange={(e)=>setOtp5(e.target.value)}/>
                                </div>
                            </div>
                            <div className='wrong-password d-none'><img src='/images/circle-info.svg'/><span className='d-block'>Wrong code, Please try again</span></div>
                            <div className="input-field mt-3">
                                <button type="submit" className={`w-100 ${loading ? "disabled " : ""}`}>
                                                {
                                                    loading ? (
                                                        <>
                                                        <Loading />
                                                    </>
                                                    ):"Verify"
                                                }
                                            </button>
                            </div>
                        </form>
                        <span className='send-txt'>Didn’t get your OTP? <a href="#">Send a new OTP</a></span>
                    </div>
                </div>
            </div>
        </div>  
        </div>
    </div>
  )
}
