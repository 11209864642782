import React,{useState} from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
export default function ConsentSignatures({setTab,setRecipientUserId,recipientUserId,userDetails,setSuccessMessage}) {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [loading,setLoading] = useState(false)
  const [medical_information,setmedical_information] = useState(userDetails.consentSignature?userDetails.consentSignature.medical_information:'')
  const [group_activities,setgroup_activities] = useState(userDetails.consentSignature?userDetails.consentSignature.group_activities:'')
  const [best_care_of_resident,setbest_care_of_resident] = useState(userDetails.consentSignature?userDetails.consentSignature.best_care_of_resident:'')
  const [resident_signature,setresident_signature] = useState(userDetails.consentSignature?userDetails.consentSignature.resident_signature:'')
  const [date,setdate] = useState(userDetails.consentSignature?userDetails.consentSignature.date:'')
  const ConsentStore = async (e)=>{
    e.preventDefault();
    var formData = new FormData();
    formData.append("recipientUserId",recipientUserId);
    formData.append("medical_information",medical_information);
    formData.append("group_activities",group_activities);
    formData.append("best_care_of_resident",best_care_of_resident);
    formData.append("resident_signature",resident_signature);
    formData.append("date",date);
        setLoading(true)
        const apicall = await LoginService.ConsentAndSignatures(auth,formData)
        if(apicall.state === true){
            apicall.result.json().then((resp)=>{
                setTab(resp.tab)
                setLoading(false)
                setSuccessMessage(resp.message)

            })
        }else
        {
            apicall.result.json().then((resp)=>{
                console.log("failed");
                setLoading(false)
            })
        }
  }
  return (
    <>
     
        <h5>Consent and Signatures</h5>
        <div className="forms ps-0 consent-box">
          <form action="" onSubmit={ConsentStore}>
            <div className="d-sm-flex gap-3 align-items-center">
              <div>
                <label className="form-check-label ms-1" for="Nap-Habits">
                  <ul className="mb-2">
                    <li>
                      Consent to Share Medical Information with Authorized
                      Personnel:
                    </li>
                  </ul>
                </label>
              </div>
              <div className="options mt-sm-0 mt-3">
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="yes"
                    checked={medical_information == '1'}
                    onChange={(e)=>setmedical_information('1')}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="no"
                    checked={medical_information == '0'}
                    onChange={(e)=>setmedical_information('0')}
                  />
                  No
                </label>
              </div>
            </div>
            <div className="d-sm-flex gap-3 align-items-center">
              <div>
                <label className="form-check-label ms-1" for="Nap-Habits">
                  <ul className="mb-2">
                    <li>Consent to Participate in Group Activities:</li>
                  </ul>
                </label>
              </div>
              <div className="options mt-sm-0 mt-3">
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="yes"
                    checked={group_activities == '1'}
                    onChange={(e)=>setgroup_activities('1')}
                  />
                  Yes
                </label>
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="no"
                    checked={group_activities == '0'}
                    onChange={(e)=>setgroup_activities('0')}
                  />
                  No
                </label>
              </div>
            </div>
            <label
              className="form-check-label ms-1 mt-3 mt-sm-0"
              for="Nap-Habits"
            >
              <ul className="mb-2">
                <li>
                  I understand that the information in this form is accurate and
                  will be used to provide the best care for the resident.
                </li>
              </ul>
            </label>
            <div className="mt-3 d-md-flex">
              <div className="">
                <p>Resident/Guardian Signature:</p>
              </div>
              <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-3 mt-sm-0" value={resident_signature} onChange={(e)=>setresident_signature(e.target.value)}/>
              </div>
            </div>
            <div className="mt-3 d-md-flex">
              <div className="mt-5 mt-sm-0">
                <p>Date:</p>
              </div>
              <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-3 mt-sm-0" value={date} onChange={(e)=>setdate(e.target.value)}/>
              </div>
            </div>
            <SaveButton setLoading={setLoading} loading={loading}/>

          </form>
        </div>
     
    </>
  );
}
