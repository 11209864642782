export const isLoggedIn = ()=>{
    let data = localStorage.getItem('user');
    if(data != null) return true;
    else return false;
}

export const isLoggedInMember = ()=>{
    let data = localStorage.getItem('familyDetails');
    if(data != null) return true;
    else return false;
}