import React,{useEffect, useState} from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../Loader/Loading";


export default function  PersonalInformation({setTab,setRecipientUserId,recipientUserId,userDetails,setSuccessMessage}) {
    console.log("hi",userDetails?userDetails.personal_information.fullName:'')
    const [fullName,setFullName] = useState(userDetails?userDetails.personal_information.fullName:'');
    const [nickName,setNickName] = useState(userDetails?userDetails.personal_information.nickName:'');
    const [dob,setDob] = useState(userDetails?userDetails.personal_information.dob:'');
    const [age,setAge] = useState(userDetails?userDetails.personal_information.age:'');
    const [nationality,setNationality] = useState(userDetails?userDetails.personal_information.nationality:'');
    const [country,setCountry] = useState(userDetails?userDetails.personal_information.country:'');
    const [state,setState] = useState(userDetails?userDetails.personal_information.state:'');
    const [city,setCity] = useState(userDetails?userDetails.personal_information.city:'');
    const [gender,setGender] = useState(userDetails?userDetails.personal_information.gender:'');
    const [ethnicity,setEthnicity] = useState(userDetails?userDetails.personal_information.ethnicity:'');
    const [religionFaith,setReligionFaith] = useState(userDetails?userDetails.personal_information.religionFaith:'');
    const [primaryLanguage,setPrimaryLanguage] = useState(userDetails?userDetails.personal_information.primaryLanguage:'');
    const [otherLanguage,setOtherLanguage] = useState(userDetails?userDetails.personal_information.otherLanguage:'');
    const [educationLevel,setEducationLevel] = useState(userDetails?userDetails.personal_information.educationLevel:'');
    const [occupation,setOccupation] = useState(userDetails?userDetails.personal_information.occupation:'');
    const [maritalStatus,setMaritalStatus] = useState(userDetails?userDetails.personal_information.maritalStatus:'');
    const [spouseName,setSpouseName] = useState(userDetails?userDetails.personal_information.spouseName:'');
    const [isChildrenAdded,setIsChildrenAdded] = useState("");
    const [permanentAddress,setPermanentAddress] = useState(userDetails?userDetails.personal_information.permanentAddress:'');
    const [currentAddress,setCurrentAddress] = useState(userDetails?userDetails.personal_information.currentAddress:'');
    const [contactPrimaryPhone,setContactPrimaryPhone] = useState(userDetails?userDetails.personal_information.contactPrimaryPhone:'');
    const [contactSecondaryPhone,setContactSecondaryPhone] = useState(userDetails?userDetails.personal_information.contactSecondaryPhone:'');
    const [contactEmail,setContactEmail] = useState(userDetails?userDetails.personal_information.contactEmail:'');
    const [isPhone,setIsPhone] = useState(userDetails?userDetails.personal_information.isPhone:false);
    const [isEmail,setIsEmail] = useState(userDetails?userDetails.personal_information.isEmail:false);
    const [isText,setIsText] = useState(userDetails?userDetails.personal_information.isText:false);
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)
    const [sameAsParmanent,setSameAsParmanent] = useState(false);

    const personalInfos = async (e)=>{
        e.preventDefault();
        setLoading(true)
        if(validate()){
            
            const item = {
                fullName:fullName,
                nickName:nickName,
                dob:dob,
                age:age,
                nationality:nationality,
                country:country,
                state:state,
                city:city,
                gender:gender,
                ethnicity:ethnicity,
                religionFaith:religionFaith,
                primaryLanguage:primaryLanguage,
                otherLanguage:otherLanguage,
                educationLevel:educationLevel,
                occupation:occupation,
                maritalStatus:maritalStatus,
                spouseName:spouseName,
                permanentAddress:permanentAddress,
                currentAddress:currentAddress,
                contactPrimaryPhone:contactPrimaryPhone,
                contactSecondaryPhone:contactSecondaryPhone,
                contactEmail:contactEmail,
                isPhone:isPhone,
                isEmail:isEmail,
                isText:isText,
                recipientUserId:recipientUserId
            }
          
            console.log(auth,item)
            const apicall = await LoginService.PersonalInfo(auth,item)
            if(apicall.state === true){
                apicall.result.json().then((resp)=>{
                    setSuccessMessage(resp.message)
                    setLoading(false)
                    setTab(resp.tab)
                    setRecipientUserId(resp.recipientUserTableId)
                })
            }else{
                apicall.result.json().then((resp)=>{
                    console.log("failed");
                    setLoading(false)
                })
            }
        }
    }
    

    const validate = ()=>{
            let result = true;
            if(fullName === ''){
                toast("Email field is required")
                result = false;
                setLoading(false)
            }
        return result;
    }

    const sameParmanent = (e) =>{
        var checked = e.target.checked;
        if(checked === true){
            setCurrentAddress(permanentAddress);
            setSameAsParmanent(true)
        }else{
            setCurrentAddress(""); 
            setSameAsParmanent(false)
        }
    }

    const parmanentAddress = (e)=>{
        setPermanentAddress(e.target.value)
        if(sameAsParmanent === true){
            setCurrentAddress(e.target.value);
        }
    }

   

   

    return (
        <>
         <h5>Personal Information</h5>
           <form action="" onSubmit={personalInfos}>
                <div className="forms">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Full Legal Name</label>
                                <input type="text" placeholder="value" value={fullName} onChange={(e)=>setFullName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Preferred Name/Nickname</label>
                                <input type="text" placeholder="value" value={nickName} onChange={(e)=>setNickName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Date of Birth</label>
                                <input type="date" placeholder="value" value={dob} onChange={(e)=>setDob(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Age</label>
                                <input type="text" placeholder="value" value={age} onChange={(e)=>setAge(e.target.value)}  />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">City</label>
                                <input type="text" placeholder="value" value={city} onChange={(e)=>setCity(e.target.value)}  />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">State</label>
                                <input type="text" placeholder="value" value={state} onChange={(e)=>setState(e.target.value)}  />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Country</label>
                                <input type="text" placeholder="value" value={country} onChange={(e)=>setCountry(e.target.value)}  />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Gender</label>
                                <input type="text" placeholder="value" value={gender} onChange={(e)=>setGender(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Nationality</label>
                                <input type="text" placeholder="value" value={nationality} onChange={(e)=>setNationality(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Ethnicity</label>
                                <input type="text" placeholder="value" value={ethnicity} onChange={(e)=>setEthnicity(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Religion/Faith</label>
                                <input type="text" placeholder="value" value={religionFaith} onChange={(e)=>setReligionFaith(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Primary Language</label>
                                <input type="text" placeholder="value" value={primaryLanguage} onChange={(e)=>setPrimaryLanguage(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Other Languages Spoken</label>
                                <input type="text" placeholder="value" value={otherLanguage} onChange={(e)=>setOtherLanguage(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Education Level</label>
                                <input type="text" placeholder="value" value={educationLevel} onChange={(e)=>setEducationLevel(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Occupation(Before Retirement):</label>
                                <input type="text" placeholder="value" value={occupation} onChange={(e)=>setOccupation(e.target.value)}  />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Current Marital Status</label>
                                <input type="text" placeholder="value" value={maritalStatus} onChange={(e)=>setMaritalStatus(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="input-field">
                                <label for="">Spouse's Name</label>
                                <input type="text" placeholder="value" value={spouseName} onChange={(e)=>setSpouseName(e.target.value)} />
                            </div>
                        </div>
                    
                        <div className="col-md-12">
                            <div className="residential">
                                <h5>Residential Information</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-field-two">
                                            <label for="">Permanent Address</label>
                                            <input type="text" placeholder="Address" value={permanentAddress} onChange={parmanentAddress} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-field-two">
                                            <label for="">Current Address (If different)</label>
                                            <input type="text" placeholder="Address" value={currentAddress} onChange={(e)=>setCurrentAddress(e.target.value)} />

                                        </div>
                                        <div className="checkbox">
                                            <input className="form-check-input" value={sameAsParmanent} type="checkbox" onChange={sameParmanent} checked={permanentAddress == currentAddress ? 'checked':''}/>
                                            <label for="">Same as permanent</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                                                        <div className="contact-inforamtion">
                                                            <h5>Contact Information</h5>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="input-field">
                                                                        <label for="">Primary Phone Number</label>
                                                                        <input type="text" placeholder="value" value={contactPrimaryPhone} onChange={(e)=>setContactPrimaryPhone(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="input-field">
                                                                        <label for="">Secondary Phone Number</label>
                                                                        <input type="text" placeholder="value" value={contactSecondaryPhone} onChange={(e)=>setContactSecondaryPhone(e.target.value)}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="input-field">
                                                                        <label for="">Email Address</label>
                                                                        <input type="text" placeholder="value" value={contactEmail} onChange={(e)=>setContactEmail(e.target.value)}  />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <p>Secondary Phone Number</p>
                                                                    <div className="checkbox">
                                                                        <input className="form-check-input" type="checkbox" onChange={(e)=>setIsPhone(e.target.checked)} checked={isPhone==1?'checked':''}/>
                                                                        <label for="">Phone</label>
                                                                        <input className="form-check-input" type="checkbox" onChange={(e)=>setIsEmail(e.target.checked)} checked={isEmail==1?'checked':''}/>
                                                                        <label for="">Email</label>
                                                                        <input className="form-check-input" type="checkbox" onChange={(e)=>setIsText(e.target.checked)} checked={isText==1?'checked':''}/>
                                                                        <label for="">Text Message</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SaveButton setLoading={setLoading} loading={loading}/>
                    </div>
                </div>
                <ToastContainer />
           </form>
           
        </>
    )
}