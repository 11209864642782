   const url = "https://stageapi.trackerhealth.io";
//  const url = "http://127.0.0.1:8000";
var LoginService = {
    
    TeamLogin : function(item){
        return  fetch(`${url}/api/login`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
             },
             body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    ForgotPassword : function(item){
        return  fetch(`${url}/api/forgot-password`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
             },
             body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    OtpVerify : function(item){
        return  fetch(`${url}/api/otpverify-save-password`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
             },
             body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    PersonalInfo : function(auth,item){
        return  fetch(`${url}/api/admin/personal-info`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json",
                "Authorization":'Bearer'+auth
             },
             body:JSON.stringify(item)
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              }); 
    },
    EmergancyContactInfo : function(auth,item){
        return  fetch(`${url}/api/admin/emergency-contact-info`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    DetailsMedicalHistory : function(auth,item){
        return  fetch(`${url}/api/admin/details-medical-history`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    FuncationAbilityAids : function(auth,item){
        return  fetch(`${url}/api/admin/funcational-ability-and-adls`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    FuncationAbilityAndAids : function(auth,item){
        return  fetch(`${url}/api/admin/funcational-ability-andadls`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    Nutrational : function(auth,item){
        return  fetch(`${url}/api/admin/nutritional-needs`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    SleepPatterns : function(auth,item){
        return  fetch(`${url}/api/admin/sleep-patterns`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    SocialHis : function(auth,item){
        return  fetch(`${url}/api/admin/social-history`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    FinancialInfo : function(auth,item){
        return  fetch(`${url}/api/admin/financial-information`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    ConsentAndSignatures : function(auth,item){
        return  fetch(`${url}/api/admin/consent-and-signature`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    OfficeUse : function(auth,item){
        return  fetch(`${url}/api/admin/for-useoffice-only`,{
            method:'POST',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
            body: item,
            redirect: 'follow'
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    Role : function(auth){
        return  fetch(`${url}/api/admin/role`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    RoleByUser : function(auth,roleId){
        return  fetch(`${url}/api/admin/rolebyuser/`+roleId,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
          
    },
    GetResident:function(auth){
        return  fetch(`${url}/api/admin/get-resident`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    GetUserDetails:function(auth,id){
        return  fetch(`${url}/api/admin/getdetails/${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteEmergency:function(auth,userid){
        return  fetch(`${url}/api/admin/delete-emergencycontact-caregiver/?recipientUserId=${userid}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteSpecialists:function(auth,userid,id){
        return  fetch(`${url}/api/admin/delete-specialists/?recipientUserId=${userid}&id=${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteChronicDiseases:function(auth,userid,id){
        return  fetch(`${url}/api/admin/delete-chronic-diseases/?recipientUserId=${userid}&id=${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteSurgicalHistory:function(auth,userid,id){
        return  fetch(`${url}/api/admin/delete-surgical-history/?recipientUserId=${userid}&id=${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteAllergies:function(auth,userid,id){
        return  fetch(`${url}/api/admin/delete-allergies/?recipientUserId=${userid}&id=${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteCurrentMedications:function(auth,userid,id){
        return  fetch(`${url}/api/admin/delete-current-medications/?recipientUserId=${userid}&id=${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    },
    DeleteOtherTreatments:function(auth,userid,id){
        return  fetch(`${url}/api/admin/delete-other-treatments/?recipientUserId=${userid}&id=${id}`,{
            method:'GET',
            headers: {"Accept": "application/json","Authorization":'Bearer'+ auth},
         }).then((result)=>{
                  if (result.status === 200) {
                      return { result: result, state: true };
                  } else {
                      return { result: result, state: false };
                  }
              }).catch((err)=>{
                 console.log(err);
              });
    }
     
}
export default LoginService