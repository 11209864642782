import React, { useState } from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
function FunctionalAbility1({setTab,setRecipientUserId,recipientUserId,userDetails,setSuccessMessage}){
    const auth = JSON.parse(localStorage.getItem("user"));
    const [current_mobility_level,setCurrent_mobility_level] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_mobility_level:'');
    const [current_mobility_level_notes,setCurrent_mobility_level_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_mobility_level_notes:'');
    const [gait_pattern,setGait_pattern] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.gait_pattern:'');
    const [gait_pattern_notes,setGait_pattern_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.gait_pattern_notes:'');
    const [balance,setBalance] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.balance:'');
    const [balance_notes,setBalance_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.balance_notes:'');
    const [leg_strength,setLeg_strength] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.leg_strength:'');
    const [leg_strength_notes,setLeg_strength_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.leg_strength_notes:'');
    const [arm_strength,setArm_strength] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.arm_strength:'');
    const [arm_strength_notes,setArm_strength_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.arm_strength_notes:'');
    const [endurance_strength,setEndurance_strength] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.endurance_strength:'');
    const [endurance_strength_notes,setEndurance_strength_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.endurance_strength_notes:'');
    const [has_the_resident,setHas_the_resident] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.has_the_resident:'');
    const [has_the_resident_notes,setHas_the_resident_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.has_the_resident == '3' ? userDetails.functionalAbility1.has_the_resident_notes:'':'');
    const [has_the_resident_notes1,setHas_the_resident_notes1] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.has_the_resident == '4'?userDetails.functionalAbility1.has_the_resident_notes:'':'');
    const [injury,setInjury] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.injury:'');
    const [injury_notes,setInjury_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.injury_notes:'');
    const [current_fall,setCurrent_fall] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_fall:'');
    const [current_fall_notes,setCurrent_fall_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_fall_notes:'');
    const [ability_to_transfer,setAbility_to_transfer] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.ability_to_transfer:'');
    const [ability_to_transfer_notes,setAbility_to_transfer_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.ability_to_transfer_notes:'');
    const [preferred_transfer,setPreferred_transfer] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.preferred_transfer:'');
    const [preferred_transfer_notes,setPreferred_transfer_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.preferred_transfer_notes:'');
    const [current_assistive,setCurrent_assistive] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_assistive:'');
    const [current_assistive_notes,setCurrent_assistive_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_assistive == '5'?userDetails.functionalAbility1.current_assistive_notes:'':'');
    const [current_assistive_notes1,setCurrent_assistive_notes1] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_assistive == '6'?userDetails.functionalAbility1.current_assistive_notes:'':'');
    const [effectiveness,setEffectiveness] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.effectiveness:'');
    const [effectiveness_notes,setEffectiveness_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.effectiveness_notes:'');
    const [bed_mobility,setBed_mobility] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.bed_mobility:'');
    const [wheelchair,setWheelchair] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.wheelchair:'');
    const [wheelchair_notes,setWheelchair_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.wheelchair_notes:'');
    const [badchair,setBadchair] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.badchair:'');
    const [positioning_device,setPositioning_device] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.positioning_device:'');
    const [positioning_device_notes,setPositioning_device_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.positioning_device_notes:'');
    const [presence_of_pain,setPresence_of_pain] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.presence_of_pain:'');
    const [presence_of_pain_notes,setPresence_of_pain_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.presence_of_pain_notes:'');
    const [pain_management,setPain_management] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.pain_management:'');
    const [pain_management_notes,setPain_management_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.pain_management == '1'?userDetails.functionalAbility1.pain_management_notes:'':'');
    const [pain_management_notes1,setPain_management_notes1] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.pain_management == '5'?userDetails.functionalAbility1.pain_management_notes:'':'');
    const [current_physical,setCurrent_physical] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_physical:'');
    const [current_physical_notes,setCurrent_physical_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.current_physical_notes:'');
    const [pain_management_interventions,setPain_management_interventions] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.pain_management_interventions:'');
    const [pain_management_interventions_notes,setPain_management_interventions_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.pain_management_interventions == '1'?userDetails.functionalAbility1.pain_management_interventions_notes:'':'');
    const [pain_management_interventions_notes1,setPain_management_interventions_notes1] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.pain_management_interventions == '5'?userDetails.functionalAbility1.pain_management_interventions_notes:'':'');
    const [therapist_name,setTherapist_name] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.therapist_name:'');
    const [contact_info,setContact_info] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.contact_info:'');
    const [goals_of_therapy,setGoals_of_therapy] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.goals_of_therapy:'');
    const [goals_of_therapy_notes,setGoals_of_therapy_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.goals_of_therapy_notes:'');
    const [specific_concerns,setSpecific_concerns] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.specific_concerns:'');
    const [bathing,setBathing] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.bathing:'');
    const [bathing_notes,setBathing_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.bathing_notes:'');
    const [dressing,setDressing] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.dressing:'');
    const [dressing_notes,setDressing_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.dressing_notes:'');
    const [eating,setEating] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.eating:'');
    const [eating_notes,setEating_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.eating_notes:'');
    const [grooming,setGrooming] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.grooming:'');
    const [grooming_notes,setGrooming_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.grooming_notes:'');
    const [bladder_control,setBladder_control] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.bladder_control:'');
    const [uses_notes,setUses_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.uses_notes:'');
    const [memory_issue,setMemory_issue] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.memory_issue:'');
    const [orientation,setOrientation] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.orientation:'');
    const [decision_making,setDecision_making] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.decision_making:'');
    const [decision_making_notes,setDecision_making_notes] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.decision_making_notes:'');

    const [uses,setUses] = useState(userDetails.functionalAbility1?userDetails.functionalAbility1.uses:[]);
    const [loading,setLoading] = useState(false)
    const [readOn,setReadOnly] = useState("readOnly")
    const [strengthRead,setStrengthRead] = useState("readOnly")
    const [armRead,setArmRead] = useState("readOnly")
    const [fallsRead1,setFallsRead1] = useState("readOnly")
    const [fallsRead2,setFallsRead2] = useState("readOnly")
    const [injuryRead,setInjuryRead] = useState("readOnly")
    const [currentFallRead,setCurrentFallRead] = useState("readOnly")
    const [preferredTransRead,setPreferredTransRead] = useState("readOnly")

    const [handleUseProRead,sethandleUseProRead] = useState("readOnly")
    const [handleUseProRead1,sethandleUseProRead1] = useState("readOnly")
    const [positionDeviceRead,setPositionDeviceRead] = useState("readOnly")
    const [painManageRead,setpainManageRead] = useState("readOnly")
    const [painManageRead1,setpainManageRead1] = useState("readOnly")
    const [painManageRead2,setpainManageRead2] = useState("readOnly")
    const [painManageRead3,setpainManageRead3] = useState("readOnly")
    const [goalsRead,setgoalsRead] = useState("readOnly")
    const [bathingRead,setBathingRead] = useState("readOnly")
    const [dressingRead,setDressingRead] = useState("readOnly")
    const [eatingRead,setEatingRead] = useState("readOnly")
    const [groomingRead,setGroomingRead] = useState("readOnly")
    const [decisionRead,setDecisionRead] = useState("readOnly")
    
    const handleGaitPattern = (arg)=>{
        setGait_pattern(arg)
        if(arg == '6'){
            setReadOnly("")
        }
        else{
            setReadOnly("readOnly")
            setGait_pattern_notes("")

        }
    }
    const handleStrength = (arg) =>{
        setLeg_strength(arg)
        if(arg == '2'){
            setStrengthRead("")
        }else{
            setLeg_strength_notes("")
            setStrengthRead("readOnly")
        }
    }
    const handleArm = (arg) =>{
        setArm_strength(arg)
        if(arg == '2'){
            setArmRead("")
        }else{
            setArm_strength_notes("")
            setArmRead("readOnly")
        }
    }
    const handleFalls = (arg) =>{
        setHas_the_resident(arg)
        if(arg == '3'){
            setFallsRead1("")
            setFallsRead2("readOnly")
            setHas_the_resident_notes1("")


        }else if(arg == '4'){
            setFallsRead2("")
            setFallsRead1("readOnly")
            setHas_the_resident_notes("")


        }
        else{
            setHas_the_resident_notes("")
            setFallsRead1("readOnly")
            setFallsRead2("readOnly")
            setHas_the_resident_notes1("")
        }
    }
    const handleInjury = (arg) =>{
        setInjury(arg)
        if(arg == '4'){
            setInjuryRead("")
        }
        else{
            setInjuryRead("readOnly")
            setInjury_notes("")
        }
    }
    const handleCurrentFall=(arg)=>{
        setCurrent_fall(arg)
        if(arg == '6'){
            setCurrentFallRead("")
        }
        else{
            setCurrentFallRead("readOnly")
            setCurrent_fall_notes("")
        }
    }
    const handlePreferredTrans=(arg)=>{
        setPreferred_transfer(arg)
        if(arg == '5'){
            setPreferredTransRead("")
        }
        else{
            setPreferredTransRead("readOnly")
            setPreferred_transfer_notes("")
        }
    }

    const [bladderRead,setBladderRead] = useState("readOnly")
    const handleBladderUses =(e)=>{
        const {value,checked} = e.target;
        if(checked)
        {
            if(value == 'other')
            {
                setBladderRead("")
            }else
            {

            }
            setUses([...uses,value])
        }else
        {
            setUses(uses.filter((e)=>e !== value))
            if(value == 'other')
            {
                setBladderRead("readOnly")
                setUses_notes("")
            }
        }
    }
    const funcationAbilityStore1 = async (e)=>{
        e.preventDefault();
      
       var formdata = new FormData();
       formdata.append("recipientUserId",recipientUserId);
       formdata.append("current_mobility_level",current_mobility_level);
       formdata.append("current_mobility_level_notes",current_mobility_level_notes);
       formdata.append("gait_pattern",gait_pattern);
       formdata.append("gait_pattern_notes",gait_pattern_notes);
       formdata.append("balance",balance);
       formdata.append("balance_notes",balance_notes);
       formdata.append("leg_strength",leg_strength);
       formdata.append("leg_strength_notes",leg_strength_notes);
       formdata.append("arm_strength",arm_strength);

       formdata.append("arm_strength_notes",arm_strength_notes);
       formdata.append("endurance_strength",endurance_strength);
       formdata.append("has_the_resident",has_the_resident);
       if(has_the_resident_notes != ''){
        formdata.append("has_the_resident_notes",has_the_resident_notes);
       }
       else{
        formdata.append("has_the_resident_notes",has_the_resident_notes1);
       }
      
       formdata.append("injury",injury);

       formdata.append("injury_notes",injury_notes);
       formdata.append("current_fall",current_fall);
       formdata.append("current_fall_notes",current_fall_notes);
       formdata.append("ability_to_transfer",ability_to_transfer);
       formdata.append("ability_to_transfer_notes",ability_to_transfer_notes);

       formdata.append("preferred_transfer",preferred_transfer);
       formdata.append("preferred_transfer_notes",preferred_transfer_notes);
       formdata.append("current_assistive",current_assistive);
       if(current_assistive == '5')
       {
        formdata.append("current_assistive_notes",current_assistive_notes);

       }else if(current_assistive == '6')
       {
        formdata.append("current_assistive_notes",current_assistive_notes1);

       }

       formdata.append("effectiveness",effectiveness);
       formdata.append("effectiveness_notes",effectiveness_notes);
       formdata.append("bed_mobility",bed_mobility);
       formdata.append("wheelchair",wheelchair);
       formdata.append("wheelchair_notes",wheelchair_notes);
       formdata.append("badchair",badchair);

       formdata.append("positioning_device",positioning_device);
       formdata.append("positioning_device_notes",positioning_device_notes);
       formdata.append("presence_of_pain",presence_of_pain);
       formdata.append("presence_of_pain_notes",presence_of_pain_notes);
       formdata.append("pain_management",pain_management);
       if(pain_management == '1')
       {
        formdata.append("pain_management_notes",pain_management_notes);
       }else if(pain_management == '5'){
        formdata.append("pain_management_notes",pain_management_notes1);
       }
      
       formdata.append("current_physical",current_physical);
       formdata.append("current_physical_notes",current_physical_notes);
       formdata.append("pain_management_interventions",pain_management_interventions);
       if(pain_management_interventions == '1'){
        formdata.append("pain_management_interventions_notes",pain_management_interventions_notes);
       }else if(pain_management_interventions =='5')
       {
        formdata.append("pain_management_interventions_notes",pain_management_interventions_notes1);
       }
       
       formdata.append("therapist_name",therapist_name);

       formdata.append("contact_info",contact_info);
       formdata.append("goals_of_therapy",goals_of_therapy);
       formdata.append("goals_of_therapy_notes",goals_of_therapy_notes);
       formdata.append("specific_concerns",specific_concerns);
       formdata.append("bathing",bathing);
       formdata.append("bathing_notes",bathing_notes);
       formdata.append("dressing",dressing);

       formdata.append("dressing_notes",dressing_notes);
       formdata.append("eating",eating);
       formdata.append("eating_notes",eating_notes);
       formdata.append("grooming",grooming);
       formdata.append("grooming_notes",grooming_notes);
       formdata.append("bladder_control",bladder_control);

       formdata.append("uses[]",uses);
       formdata.append("uses_notes",uses_notes);
       formdata.append("memory_issue",memory_issue);
       formdata.append("orientation",orientation);
       formdata.append("decision_making",decision_making);
       formdata.append("decision_making_notes",decision_making_notes);
        setLoading(true)
      
       const apicall = await LoginService.FuncationAbilityAndAids(auth,formdata)
       if(apicall.state === true){
        apicall.result.json().then((resp)=>{
            setTab(resp.tab)
            setLoading(false)
            setSuccessMessage(resp.message)
        })
        }else{
        apicall.result.json().then((resp)=>{
            console.log("failed");
            setLoading(false)
        })
    }
    }
    
    const handleUseProCheck = (arg)=>
    {
        if(arg == '5')
        {
            setCurrent_assistive(arg)
            sethandleUseProRead("")
            setCurrent_assistive_notes1("")
            sethandleUseProRead1('readOnly')

        }
        else if(arg == '6')
        {
            setCurrent_assistive(arg)
            sethandleUseProRead1("")  
            sethandleUseProRead('readOnly')
            setCurrent_assistive_notes("")
        }
       
    }

    const handlePositionDevice = (arg)=>
    {
        if(arg == '1')
        {
            setPositioning_device(arg)
            setPositionDeviceRead("readOnly")
            setPositioning_device_notes("")

        }else if(arg == '2')
        {
            setPositioning_device(arg)
            setPositionDeviceRead("readOnly")
            setPositioning_device_notes("")

        }
        else if(arg == '3')
        {
            setPositioning_device(arg)
            setPositionDeviceRead("readOnly")
            setPositioning_device_notes("")
        }
        else if(arg == '4')
        {
            setPositioning_device(arg)
            setPositionDeviceRead("")
        }
    }

    const handlePainManage = (arg) =>{
        if(arg == '1')
        {
            setPain_management_interventions(arg)
            setpainManageRead("")
            setPain_management_interventions_notes1("")
        }else if(arg == '2')
        {
            setPain_management_interventions(arg)
            setpainManageRead("readOnly")
            setpainManageRead1("readOnly")
            setPain_management_interventions_notes1("")
            setPain_management_interventions_notes("")

        }
        else if(arg == '3')
        {
            setPain_management_interventions(arg)
            setpainManageRead("readOnly")
            setpainManageRead1("readOnly")
            setPain_management_interventions_notes1("")
            setPain_management_interventions_notes("")
        }
        else if(arg == '4')
        {
            setPain_management_interventions(arg)
            setpainManageRead("readOnly")
            setpainManageRead1("readOnly")
            setPain_management_interventions_notes1("")
            setPain_management_interventions_notes("")
        }
        else if(arg == '5')
        {
            setPain_management_interventions(arg)
            setpainManageRead1("")
            setPain_management_interventions_notes("")
        }
    }

    const handlePainManage1 = (arg)=>{
        if(arg == '1')
        {
            setPain_management(arg)
            setpainManageRead2("")
            setpainManageRead3("readOnly")
            setPain_management_notes1("")
        }
        else if(arg == '2')
        {
            setPain_management(arg)
            setpainManageRead2("readOnly")
            setpainManageRead3("readOnly")
            setPain_management_notes("")
            setPain_management_notes1("")
        }
        else if(arg == '3')
        {
            setPain_management(arg)
            setpainManageRead2("readOnly")
            setpainManageRead3("readOnly")
            setPain_management_notes("")
            setPain_management_notes1("")
        }
        else if(arg == '4')
        {
            setPain_management(arg)
            setpainManageRead2("readOnly")
            setpainManageRead3("readOnly")
            setPain_management_notes("")
            setPain_management_notes1("")
        }
        else if(arg == '5')
        {
            setPain_management(arg)
            setpainManageRead2("readOnly")
            setpainManageRead3("")
            setPain_management_notes("")
        }
    }

    const handleGoalsTherapy=(arg)=>{
        if(arg == '1' || arg == '2' || arg == '3' || arg == '4' || arg == '5')
        {
            setGoals_of_therapy(arg)  
            setGoals_of_therapy_notes("")
            setgoalsRead("readOnly")
        }
        else if(arg == '6')
        {
            setGoals_of_therapy(arg) 
            setgoalsRead("") 
        }
    }

    const handleBathing = (arg)=>{
        if(arg == '1' || arg == '2' || arg == '3')
        {
            setBathing(arg)
            setBathingRead("readOnly")
            setBathing_notes("")
        }
        else{
            setBathing(arg) 
            setBathingRead("")
        }
    }

    const handleDressing = (arg)=>{
        if(arg == '1' || arg == '2' || arg == '3')
        {
            setDressing(arg)
            setDressingRead("readOnly")
            setDressing_notes("")
        }
        else{
            setDressing(arg) 
            setDressingRead("")
        }
    }

    const handleEating = (arg)=>{
        if(arg == '1' || arg == '2' || arg == '3')
        {
            setEating(arg)
            setEatingRead("readOnly")
            setEating_notes("")
        }
        else{
            setEating(arg) 
            setEatingRead("")
        }
    }

    const handleGrooming = (arg)=>{
        if(arg == '1' || arg == '2' || arg == '3')
        {
            setGrooming(arg)
            setGroomingRead("readOnly")
            setGrooming_notes("")
        }
        else{
            setGrooming(arg) 
            setGroomingRead("")
        }
    }

    const handleDecision = (arg)=>{
        if(arg == '1' || arg == '2' || arg == '3')
        {
            setDecision_making(arg)
            setDecisionRead("readOnly")
            setDecision_making_notes("")
        }
        else{
            setDecision_making(arg) 
            setDecisionRead("")
        }
    }

   
    
    return(
        <>
         <h5>Functional Ability And ADLs (Activities of Daily Living)</h5>
                                        <div className="forms Functional d-none">
                                            <form className="adls-form">
                                                <fieldset className="">
                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Mobility Assessment</li>
                                                        </ul>

                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-independent" value="fully-independent" />
                                                            <label className="form-check-label" for="fully-independent">
                                                                Fully Independent
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="minimal-assistance" value="minimal-assistance" />
                                                            <label className="form-check-label" for="minimal-assistance">
                                                                Requires minimal assistance (explain)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="moderate-assistance" value="moderate-assistance" />
                                                            <label className="form-check-label" for="moderate-assistance">
                                                                Requires moderate assistance (explain)
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="mobility" id="fully-dependent" value="fully-dependent" />
                                                            <label className="form-check-label" for="fully-dependent">
                                                                Fully dependent
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Walking aids used</li>
                                                        </ul>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="none" value="none" />
                                                            <label className="form-check-label" for="none">
                                                                None
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="cane" value="cane" />
                                                            <label className="form-check-label" for="cane">
                                                                Cane
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="walker" value="walker" />
                                                            <label className="form-check-label" for="walker">
                                                                Walker
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="wheelchair" value="wheelchair" />
                                                            <label className="form-check-label" for="wheelchair">
                                                                Wheelchair
                                                            </label>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <input className="form-check-input" type="checkbox" name="walking-aids" id="other" value="other" />
                                                            <div className="last-dose d-flex align-items-center ms-2">
                                                                <label for="other">
                                                                    Other
                                                                </label>
                                                                <input type="text" className="" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <ul>
                                                            <li>Appointment Scheduled with Physiotherapist</li>
                                                        </ul>
                                                        <div className="d-flex">
                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-yes" value="yes" />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-yes">
                                                                Yes
                                                            </label>

                                                            <input className="form-check-input" type="checkbox" name="appointment" id="appointment-no" value="no" />
                                                            <label className="form-check-label ms-1 me-2" for="appointment-no">
                                                                No
                                                            </label>

                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                        <div className="Mobility">
                                            <h5>Mobility Assessment</h5>
                                            <div className="forms pt-0">
                                                <form action="" onSubmit={funcationAbilityStore1}>
                                                <div className="General-Mobility">
                                                    <p>1. General Mobility Status</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Current Mobility Level (Check all that apply)</label>
                                                        </li>
                                                        <div className="General-Mobility-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Fully-Independent" id="Fully-Independent" value="Fully-Independent" checked={current_mobility_level == "1"?'checked':''} onChange={e=>setCurrent_mobility_level("1")}/>
                                                                <label className="form-check-label" for="Fully-Independent">
                                                                    Fully Independent (Able to move freely without assistance)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={current_mobility_level == "2"?'checked':''}  onChange={e=>setCurrent_mobility_level("2")}/>
                                                                <label className="form-check-label" for="Independent">
                                                                    Independent with Aids (Uses a cane, walker, or other assistive devices independently)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Minimal" id="Needs-Minimal" value="Needs-Minimal" checked={current_mobility_level == "3"?'checked':''}  onChange={e=>setCurrent_mobility_level("3")} />
                                                                <label className="form-check-label" for="Needs-Minimal">
                                                                    Needs Minimal Assistance (Occasional help with balance, walking, or transfers)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Moderate" id="Needs-Moderate" value="Needs-Moderate" checked={current_mobility_level == "4"?'checked':''}  onChange={e=>setCurrent_mobility_level("4")}/>
                                                                <label className="form-check-label" for="Needs-Moderate">
                                                                    Needs Moderate Assistance (Requires help with transfers and walking, but can bear some weight)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Full" id="Needs-Full" value="Needs-Full" checked={current_mobility_level == "5"?'checked':''}  onChange={e=>setCurrent_mobility_level("5")}/>
                                                                <label className="form-check-label" for="Needs-Full">
                                                                    Needs Full Assistance (Dependent on caregivers for transfers and movement)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Non-ambulatory" id="Non-ambulatory" value="Non-ambulatory" checked={current_mobility_level == "6"?'checked':''}  onChange={e=>setCurrent_mobility_level("6")}/>
                                                                <label className="form-check-label" for="Non-ambulatory">
                                                                    Non-ambulatory (Unable to walk or transfer without full assistance)
                                                                </label>
                                                            </div>
                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>Additional Notes on Mobility Status:</label>
                                                                <div className="last-dose w-md-50">
                                                                    <input type="text" className="" value={current_mobility_level_notes} onChange={(e)=>setCurrent_mobility_level_notes(e.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                </div>
                                                <div className="Gait-Balance mt-3">
                                                    <p>2. Gait and Balance</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Gait Pattern (How the resident walks)</label>
                                                        </li>
                                                        <div className="Gait-Balance-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Normal" id="Normal" value="Normal" checked={gait_pattern == "1"?'checked':''} onChange={e=>handleGaitPattern("1")}/>
                                                                <label className="form-check-label" for="Normal">
                                                                    Normal
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Shuffling" id="Shuffling" value="Shuffling" checked={gait_pattern == "2"?'checked':''} onChange={e=>handleGaitPattern("2")}/>
                                                                <label className="form-check-label" for="Shuffling">
                                                                    Shuffling (small steps, difficulty lifting feet)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Unsteady" id="Unsteady" value="Unsteady" checked={gait_pattern == "3"?'checked':''} onChange={e=>handleGaitPattern("3")}/>
                                                                <label className="form-check-label" for="Unsteady">
                                                                    Unsteady (risk of falling, requires supervision)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Hemiplegic" id="Hemiplegic" value="Hemiplegic" checked={gait_pattern == "4"?'checked':''} onChange={e=>handleGaitPattern("4")}/>
                                                                <label className="form-check-label" for="Hemiplegic">
                                                                    Hemiplegic (paralysis/weakness on one side)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Ataxic" id="Ataxic" value="Ataxic" checked={gait_pattern == "5"?'checked':''} onChange={e=>handleGaitPattern("5")}/>
                                                                <label className="form-check-label" for="Ataxic">
                                                                    Ataxic (uncoordinated, staggering movements)
                                                                </label>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <input class="form-check-input" type="checkbox" name="walking-aids" id="other" value="other" checked={gait_pattern == "6"?'checked':''} onChange={e=>handleGaitPattern("6")}/>
                                                                <div class="last-dose d-flex align-items-center ms-2">
                                                                    <label for="other">Other</label>
                                                                    <input type="text" class="" value={gait_pattern_notes} onChange={(e)=>setGait_pattern_notes(e.target.value)} readOnly={readOn}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Balance">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Balance</label>
                                                            </li>
                                                            <div className="Gait-Balance-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Stable" id="Stable" value="Stable" checked={balance == "1"?'checked':''} onChange={e=>setBalance("1")}/>
                                                                    <label className="form-check-label" for="Stable">
                                                                        Stable (No issues with balance)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Occasional" id="Occasional" value="Occasional" checked={balance == "2"?'checked':''} onChange={e=>setBalance("2")}/>
                                                                    <label className="form-check-label" for="Occasional">
                                                                        Occasional Imbalance (Needs support at times)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Frequent" id="Frequent" value="Frequent" checked={balance == "3"?'checked':''} onChange={e=>setBalance("3")}/>
                                                                    <label className="form-check-label" for="Frequent">
                                                                        Frequent Imbalance (Requires close supervision)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Falls" id="Falls" value="Falls" checked={balance == "4"?'checked':''} onChange={e=>setBalance("4")}/>
                                                                    <label className="form-check-label" for="Falls">
                                                                        Falls Frequently
                                                                    </label>
                                                                </div>
                                                                <div class="d-flex align-items-center mt-2">
                                                                    <label className="form-check-label" for="other">Notes on Balance Issues:</label>
                                                                    <input type="text" class="border-0 border-bottom border-dark" value={balance_notes} onChange={(e)=>setBalance_notes(e.target.value)}/>
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Strength mt-3">
                                                    <p>3. Strength and Endurance</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Leg Strength</label>
                                                        </li>
                                                        <div className="Strength-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Normal" id="Normal" value="Normal" checked={leg_strength == "1"?'checked':''} onChange={e=>handleStrength("1")}/>
                                                                <label className="form-check-label" for="Normal">
                                                                    Normal
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Weakness-in-One-Leg" id="Weakness-in-One-Leg" value="Weakness-in-One-Leg" checked={leg_strength == "2"?'checked':''} onChange={e=>handleStrength("2")}/>
                                                                <label className="form-check-label" for="Shuffling">
                                                                    Weakness in One Leg
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Specify:</label>
                                                                    <input type="text" className="w-md-25" value={leg_strength_notes} onChange={(e)=>setLeg_strength_notes(e.target.value)} readOnly={strengthRead} />
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Weakness-in-Both-Legs" id="Weakness-in-Both-Legs" value="Weakness-in-Both-Legs" checked={leg_strength == "3"?'checked':''} onChange={e=>handleStrength("3")}/>
                                                                <label className="form-check-label" for="Weakness-in-Both-Legs">
                                                                    Weakness in Both Legs
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Paralysis" id="Paralysis" value="Paralysis" checked={leg_strength == "4"?'checked':''} onChange={e=>handleStrength("4")}/>
                                                                <label className="form-check-label" for="Paralysis">
                                                                    Paralysis in One or Both Legs
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Arm-Strength">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Arm Strength</label>
                                                            </li>
                                                            <div className="Arm-Strength-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Normal" id="Normal" value="Normal" checked={arm_strength == "1"?'checked':''} onChange={e=>handleArm("1")}/>
                                                                    <label className="form-check-label" for="Normal">
                                                                        Normal
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Weakness-in-One-Arm" id="Weakness-in-One-Arm" value="Weakness-in-One-Arm" checked={arm_strength == "2"?'checked':''} onChange={e=>handleArm("2")}/>
                                                                    <label className="form-check-label" for="Weakness-in-One-Ar">
                                                                        Weakness in One Arm
                                                                    </label>
                                                                    <div class="last-dose d-flex">
                                                                        <label for="other">Specify:</label>
                                                                        <input type="text" className="w-md-25" value={arm_strength_notes} onChange={(e)=>setArm_strength_notes(e.target.value)} readOnly={armRead}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Weakness-in-Both-Arm" id="Weakness-in-Both-Arm" value="Weakness-in-Both-Arm" checked={arm_strength == "3"?'checked':''} onChange={e=>handleArm("3")}/>
                                                                    <label className="form-check-label" for="Weakness-in-Both-Arm">
                                                                        Weakness in Both Arm
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Paralysis" id="Paralysis" value="Paralysis" checked={arm_strength == "4"?'checked':''} onChange={e=>handleArm("4")}/>
                                                                    <label className="form-check-label" for="Paralysis">
                                                                        Paralysis in One or Both Arm
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>

                                                    <div className="Endurance">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Endurance</label>
                                                            </li>
                                                            <div className="Endurance-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="High" id="High" value="High" checked={endurance_strength == "1"?'checked':''} onChange={e=>setEndurance_strength("1")}/>
                                                                    <label className="form-check-label" for="High">
                                                                        High (Able to walk long distances without fatigue)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Moderate" id="Moderate" value="Moderate" checked={endurance_strength == "2"?'checked':''}onChange={e=>setEndurance_strength("2")}/>
                                                                    <label className="form-check-label" for="Moderate">
                                                                        Moderate (Able to walk short distances but fatigues easily)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Low" id="Low" value="Low" checked={endurance_strength == "3"?'checked':''} onChange={e=>setEndurance_strength("3")}/>
                                                                    <label className="form-check-label" for="Low">
                                                                        Low (Limited endurance, needs frequent rest breaks)
                                                                    </label>
                                                                </div>
                                                                <div class=" d-flex align-items-center mt-2">
                                                                    <label className="form-check-label" for="other">Notes on Balance Issues:</label>
                                                                    <input type="text" class="border-0 border-bottom border-dark" value={endurance_strength_notes} onChange={(e)=>setEndurance_strength_notes(e.target.value)}/>
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>

                                                </div>
                                                <div className="Falls-history mt-3">
                                                    <p>4. Falls History</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Has the Resident Experienced Falls in the Past Year?</label>
                                                        </li>
                                                        <div className="Falls-history-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Yes" id="Yes" value="Yes" checked={has_the_resident == "1"?'checked':''} onChange={e=>handleFalls("1")}/>
                                                                <label className="form-check-label" for="Yes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="No" id="No" value="No" checked={has_the_resident == "2"?'checked':''} onChange={e=>handleFalls("2")} />
                                                                <label className="form-check-label" for="No">
                                                                    No
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Many-Falls" id="Many-Falls" value="Many-Falls" checked={has_the_resident == "3"?'checked':''} onChange={e=>handleFalls("3")}/>
                                                                <label className="form-check-label" for="Many-Falls">
                                                                    If Yes, How Many Falls?
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Specify:</label>
                                                                    <input type="text" className="w-md-25" value={has_the_resident_notes} onChange={(e)=>setHas_the_resident_notes(e.target.value)} readOnly={fallsRead1}/>
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Circumstances" id="Circumstances" value="Circumstances" checked={has_the_resident == "4"?'checked':''} onChange={e=>handleFalls("4")}/>
                                                                <label className="form-check-label" for="Circumstances">
                                                                    Circumstances of Falls (e.g., location, time of day, activities leading to falls)
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Specify:</label>
                                                                    <input type="text" className="w-md-25"
                                                                    value={has_the_resident_notes1} onChange={(e)=>setHas_the_resident_notes1(e.target.value)} readOnly={fallsRead2}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Injury">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Injury from Falls</label>
                                                            </li>
                                                            <div className="Injury-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="No-Injuries" id="No-Injuries" value="No-Injuries" checked={injury == "1"?'checked':''} onChange={e=>handleInjury("1")}/>
                                                                    <label className="form-check-label" for="No-Injuries">
                                                                        No Injuries
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Minor" id="Minor" value="Minor" checked={injury == "2"?'checked':''} onChange={e=>handleInjury("2")}/>
                                                                    <label className="form-check-label" for="Minor">
                                                                        Minor Injuries (e.g., bruises, scrapes)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Major" id="Major" value="Major" checked={injury == "3"?'checked':''} onChange={e=>handleInjury("3")}/>
                                                                    <label className="form-check-label" for="Major">
                                                                        Major Injuries (e.g., fractures, head injury)
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Details" id="Weakness-in-One-Arm" value="Details" checked={injury == "4"?'checked':''} onChange={e=>handleInjury("4")} />
                                                                    <label className="form-check-label" for="Details">
                                                                        Details on Injuries from Falls
                                                                    </label>
                                                                    <div class="last-dose d-flex">
                                                                        <label for="other">Specify:</label>
                                                                        <input type="text" className="w-md-25" value={injury_notes} onChange={(e)=>setInjury_notes(e.target.value)} readOnly={injuryRead}/>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </ul>
                                                    </div>

                                                    <div className="Current-fall">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Current Fall Prevention Strategies in Place (Check all that apply)</label>
                                                            </li>
                                                            <div className="Current-fall-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Bed" id="Bed" value="Bed" checked={current_fall == "1"?'checked':''} onChange={e=>handleCurrentFall("1")}/>
                                                                    <label className="form-check-label" for="Bed">
                                                                        Bed/Chair Alarms
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Non-Slip" id="Non-Slip" value="Non-Slip" checked={current_fall == "2"?'checked':''} onChange={e=>handleCurrentFall("2")}/>
                                                                    <label className="form-check-label" for="Non-Slip">
                                                                        Non-Slip Footwear
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Grab-Bars" id="Grab-Bars" value="Grab-Bars" checked={current_fall == "3"?'checked':''} onChange={e=>handleCurrentFall("3")}/>
                                                                    <label className="form-check-label" for="Grab-Bars">
                                                                        Grab Bars in Bathroom/Shower
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Increased" id="Increased" value="Increased" checked={current_fall == "4"?'checked':''} onChange={e=>handleCurrentFall("4")}/>
                                                                    <label className="form-check-label" for="Increased">
                                                                        Increased Supervision/Monitoring
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Hip" id="Hip" value="Hip" checked={current_fall == "5"?'checked':''} onChange={e=>handleCurrentFall("5")}/>
                                                                    <label className="form-check-label" for="Hip">
                                                                        Hip Protectors
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" checked={current_fall == "6"?'checked':''} onChange={e=>handleCurrentFall("6")}/>
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" class="border-0 border-bottom border-dark" value={current_fall_notes} onChange={(e)=>setCurrent_fall_notes(e.target.value)} readOnly={currentFallRead}/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </ul>
                                                    </div>

                                                </div>
                                                <div className="Falls-history mt-3">
                                                    <p>5. Transfer Abilities (Bed, Chair, Toilet)</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Ability to Transfer (Moving from one position to another):</label>
                                                        </li>
                                                        <div className="Falls-history-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Fully-Independent" id="Fully-Independent" value="Fully-Independent" checked={ability_to_transfer == "1"?'checked':''} onChange={e=>setAbility_to_transfer("1")}/>
                                                                <label className="form-check-label" for="Fully-Independent">
                                                                    Fully Independent (Transfers without assistance)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs" id="Needs" value="Needs" checked={ability_to_transfer == "2"?'checked':''} onChange={e=>setAbility_to_transfer("2")}/>
                                                                <label className="form-check-label" for="Needs">
                                                                    Needs Supervision (Able to transfer independently but requires supervision)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Minimal" id="Needs-Minimal" value="Needs-Minimal" checked={ability_to_transfer == "3"?'checked':''} onChange={e=>setAbility_to_transfer("3")}/>
                                                                <label className="form-check-label" for="Needs-Minimal">
                                                                    Needs Minimal Assistance (Requires occasional help with transfers)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Moderate" id="Needs-Moderate" value="Needs-Moderate" checked={ability_to_transfer == "4"?'checked':''} onChange={e=>setAbility_to_transfer("4")}/>
                                                                <label className="form-check-label" for="Needs-Moderate">
                                                                    Needs Moderate Assistance (Requires frequent help, but can bear some weight)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Full" id="Needs-Full" value="Needs-Full" checked={ability_to_transfer == "5"?'checked':''} onChange={e=>setAbility_to_transfer("5")}/>
                                                                <label className="form-check-label" for="Needs-Full">
                                                                    Needs-Full Assistance (Dependent on caregiver for all transfers)
                                                                </label>
                                                            </div>
                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>Notes on Endurance Levels:</label>
                                                                <div class="last-dose w-md-50">
                                                                    <input type="text" className="" value={ability_to_transfer_notes} onChange={(e)=>setAbility_to_transfer_notes(e.target.value)}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Preferred-Transfer">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Preferred Transfer Methods (e.g., pivot transfer, use of lift)</label>
                                                            </li>
                                                            <div className="Preferred-Transfer-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Stand-Pivot" id="Stand-Pivot" value="Stand-Pivot" checked={preferred_transfer == "1"?'checked':''} onChange={e=>handlePreferredTrans("1")}/>
                                                                    <label className="form-check-label" for="Stand-Pivot">
                                                                        Stand-Pivot Transfer (Assistance standing and turning)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Sliding-Board" id="Sliding-Board" value="Sliding-Board" checked={preferred_transfer == "2"?'checked':''} onChange={e=>handlePreferredTrans("2")}/>
                                                                    <label className="form-check-label" for="Sliding-Board">
                                                                        Sliding Board (Helps slide from one surface to another)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Mechanical" id="Mechanical" value="Mechanical" checked={preferred_transfer == "3"?'checked':''} onChange={e=>handlePreferredTrans("3")}/>
                                                                    <label className="form-check-label" for="Mechanical">
                                                                        Mechanical Lift (Hoyer lift, ceiling lift)
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Two-Person" id="Two-Person" value="Two-Person" checked={preferred_transfer == "4"?'checked':''} onChange={e=>handlePreferredTrans("4")}/>
                                                                    <label className="form-check-label" for="Two-Person">
                                                                        Two-Person Transfer (Requires two caregivers for safe transfer)
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" checked={preferred_transfer == "5"?'checked':''} onChange={e=>handlePreferredTrans("5")}/>
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" class="border-0 border-bottom border-dark" value={preferred_transfer_notes} onChange={(e)=>setPreferred_transfer_notes(e.target.value)} readOnly={preferredTransRead}/>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Assistive-Devices mt-3">
                                                    <p>6. Use of Assistive Devices</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Current Assistive Devices Used (Check all that apply):</label>
                                                        </li>
                                                        <div className="Assistive-Devices-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Cane" id="Cane" value="Cane" checked={current_assistive == "1"?'checked':''} onChange={e=>setCurrent_assistive("1")}/>
                                                                <label className="form-check-label" for="Cane">
                                                                    Cane
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Walker" id="Walker" value="Walkero" checked={current_assistive == "2"?'checked':''} onChange={e=>setCurrent_assistive("2")}/>
                                                                <label className="form-check-label" for="No">
                                                                    Walker
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Scooter" id="Scooter" value="Scooter" checked={current_assistive == "3"?'checked':''} onChange={e=>setCurrent_assistive("3")}/>
                                                                <label className="form-check-label" for="Scooter">
                                                                    Scooter
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Crutches" id="Crutches" value="Crutches" checked={current_assistive == "4"?'checked':''} onChange={e=>setCurrent_assistive("4")}/>
                                                                <label className="form-check-label" for="Crutches">
                                                                    Crutches
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Orthotic-Braces" id="Orthotic-Braces" value="Orthotic-Braces" checked={current_assistive == "5"?'checked':''} onChange={()=>handleUseProCheck("5")} />
                                                                <label className="form-check-label" for="Orthotic-Braces">
                                                                    Orthotic Braces
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Type:</label>
                                                                    <input type="text" className="w-25" value={current_assistive_notes} readOnly={handleUseProRead} onChange={(e)=>setCurrent_assistive_notes(e.target.value)}/>
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Prosthetics" id="Prosthetics" value="Prosthetics" checked={current_assistive == "6"?'checked':''} onChange={()=>handleUseProCheck("6")} />
                                                                <label className="form-check-label" for="Prosthetics">
                                                                    Prosthetics
                                                                </label>
                                                                <div class="last-dose d-flex">
                                                                    <label for="other">Type:</label>
                                                                    <input type="text" className="w-25" value={current_assistive_notes1} onChange={(e)=>setCurrent_assistive_notes1(e.target.value)} readOnly={handleUseProRead1}/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Effectiveness">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Effectiveness of Assistive Devices</label>
                                                            </li>
                                                            <div className="Effectiveness-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Very-Effective" id="Very-Effective" value="Very-Effective" checked={effectiveness == "1"?'checked':''} onChange={e=>setEffectiveness("1")}/>
                                                                    <label className="form-check-label" for="Very-Effective">
                                                                        Very Effective (Resident can use devices independently)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Moderately" id="Moderately" value="Moderately" checked={effectiveness == "2"?'checked':''} onChange={e=>setEffectiveness("2")}/>
                                                                    <label className="form-check-label" for="Moderately">
                                                                        Moderately Effective (Occasional difficulty using devices)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Ineffective" id="Ineffective" value="Ineffective" checked={effectiveness == "3"?'checked':''} onChange={e=>setEffectiveness("3")}/>
                                                                    <label className="form-check-label" for="Ineffective">
                                                                        Ineffective (Needs supervision/assistance while using devices)
                                                                    </label>
                                                                </div>

                                                                <div className="d-md-flex align-item-ceter mt-2">
                                                                    <label>Notes on Endurance Levels:</label>
                                                                    <div className="last-dose w-md-50">
                                                                        <input type="text" className="" value={effectiveness_notes} onChange={(e)=>setEffectiveness_notes(e.target.value)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Bed-Mobility mt-3">
                                                    <p>7. Specialized Mobility Support Needs</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Bed Mobility</label>
                                                        </li>
                                                        <div className="Bed-Mobility-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={bed_mobility == "1"?'checked':''} onChange={e=>setBed_mobility("1")} />
                                                                <label className="form-check-label" for="Independent">
                                                                    Independent (Able to turn and reposition without help)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Supervision" id="Needs-Supervision" value="Needs-Supervision" checked={bed_mobility == "2"?'checked':''} onChange={e=>setBed_mobility("2")}/>
                                                                <label className="form-check-label" for="No">
                                                                    Needs Supervision (Able to move with supervision)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Needs-Assistance" id="Needs-Assistance" value="Needs-Assistance" checked={bed_mobility == "3"?'checked':''} onChange={e=>setBed_mobility("3")}/>
                                                                <label className="form-check-label" for="Needs-Assistance">
                                                                    Needs Assistance (Requires assistance to turn and reposition)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Fully-Dependent" id="Fully-Dependent" value="Fully-Dependent" checked={bed_mobility == "4"?'checked':''} onChange={e=>setBed_mobility("4")}/>
                                                                <label className="form-check-label" for="Fully-Dependent">
                                                                    Fully Dependent (Unable to move without full assistance)
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </ul>
                                                    <div className="Wheelchair">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Wheelchair Use</label>
                                                            </li>
                                                            <div className="Wheelchair-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="self-Propelled" id="self-Propelled" value="self-Propelled" checked={wheelchair == "1"?'checked':''} onChange={e=>setWheelchair("1")}/>
                                                                    <label className="form-check-label" for="self-Propelled">
                                                                        Self-Propelled (Able to use wheelchair independently)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Requires-Assistance" id="Requires-Assistance" value="Requires-Assistance" checked={wheelchair == "2"?'checked':''} onChange={e=>setWheelchair("2")}/>
                                                                    <label className="form-check-label" for="Requires-Assistance">
                                                                        Requires Assistance (Needs help to propel or navigate wheelchair)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fully-Dependent" id="Fully-Dependent" value="Fully-Dependent" checked={wheelchair == "3"?'checked':''} onChange={e=>setWheelchair("3")}/>
                                                                    <label className="form-check-label" for="Fully-Dependent">
                                                                        Fully Dependent (Unable to use wheelchair without full assistance)
                                                                    </label>
                                                                </div>

                                                                <div className="d-md-flex align-item-ceter mt-2">
                                                                    <label>Notes on Wheelchair Use (e.g., posture support, cushions):</label>
                                                                    <div className="last-dose w-md-50">
                                                                        <input type="text" className="" value={wheelchair_notes} onChange={(e)=>setWheelchair_notes(e.target.value)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Bed-Chair">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Bed/Chair Alarms :</label>
                                                            </li>
                                                            <div className="Bed-Chair-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Not-Used" id="Not-Used" value="Not-Used" checked={badchair == "1"?'checked':''} onChange={e=>setBadchair("1")} />
                                                                    <label className="form-check-label" for="Not-Used">
                                                                        Not Used
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Used-Occasionally" id="Used-Occasionally" value="Used-Occasionally" checked={badchair == "2"?'checked':''} onChange={e=>setBadchair("2")}/>
                                                                    <label className="form-check-label" for="Used-Occasionally">
                                                                        Used Occasionally (For safety during specific times)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Used-Regularly" id="Used-Regularly" value="Used-Regularly" checked={badchair == "3"?'checked':''} onChange={e=>setBadchair("3")}/>
                                                                    <label className="form-check-label" for="Used-Regularly">
                                                                        Used Regularly (For consistent monitoring and fall prevention)
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Positioning-Devices">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Positioning Devices :</label>
                                                            </li>
                                                            <div className="Positioning-Devices">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Not-Needed" id="Not-Needed" value="Not-Needed" checked={positioning_device == "1"?'checked':''} onChange={()=>handlePositionDevice("1")}/>
                                                                    <label className="form-check-label" for="Not-Needed">
                                                                        Not Needed
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Special-Cushions" id="Special-Cushions" value="Special-Cushions" checked={positioning_device == "2"?'checked':''} onChange={()=>handlePositionDevice("2")}/>
                                                                    <label className="form-check-label" for="Special-Cushions">
                                                                        Special Cushions (e.g., for pressure relief)
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Wedges" id="Wedges" value="Wedges" checked={positioning_device == "3"?'checked':''} onChange={()=>handlePositionDevice("3")}/>
                                                                    <label className="form-check-label" for="Wedges">
                                                                        Wedges (e.g., to help with positioning in bed or chair)
                                                                    </label>
                                                                </div>

                                                                <div class="d-flex align-item-center">
                                                                    <input class="form-check-input" type="checkbox" checked={positioning_device == "4"?'checked':''} onChange={()=>handlePositionDevice("4")}/>
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label class="form-check-label" for="other">Other</label>
                                                                        <input type="text" class="border-0 border-bottom border-dark" value={positioning_device_notes} onChange={(e)=>setPositioning_device_notes(e.target.value)} readOnly={positionDeviceRead} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Presence mt-3">
                                                    <p>8. Pain Management Related to Mobility</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Presence of Pain During Movement:</label>
                                                        </li>
                                                        <div className="Presence-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="No-Pain" id="No-Pain" value="No-Pain" checked={presence_of_pain == "1"?'checked':''} onChange={e=>setPresence_of_pain("1")}/>
                                                                <label className="form-check-label" for="No-Pain">
                                                                    No Pain
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Mild-Pain" id="Mild-Pain" value="Mild-Pain" checked={presence_of_pain == "2"?'checked':''} onChange={e=>setPresence_of_pain("2")}/>
                                                                <label className="form-check-label" for="Mild-Pain">
                                                                    Mild Pain (Occasional discomfort during movement)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Moderate-Pain" id="Moderate-Paine" value="Moderate-Pain" checked={presence_of_pain == "3"?'checked':''} onChange={e=>setPresence_of_pain("3")}/>
                                                                <label className="form-check-label" for="Moderate-Pain">
                                                                    Moderate Pain (Regular discomfort, managed with interventions)
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Severe-Pain" id=" Severe-Pain" value="Severe-Pain" checked={presence_of_pain == "4"?'checked':''} onChange={e=>setPresence_of_pain("4")}/>
                                                                <label className="form-check-label" for=" Severe-Pain">
                                                                    Severe Pain (Requires regular pain management during movement)
                                                                </label>
                                                            </div>
                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>Location of Pain (e.g., knees, back, hips):</label>
                                                                <div className="last-dose w-md-50">
                                                                    <input type="text" className="" value={presence_of_pain_notes} onChange={(e)=>setPresence_of_pain_notes(e.target.value)}/>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </ul>
                                                    <div className="Pain-Management">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Pain Management Interventions (Check all that apply):</label>
                                                            </li>
                                                            <div className="Pain-Management-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Medications" id="Medications"
                                                                     value="Medications" checked={pain_management_interventions == "1"?'checked':''} 
                                                                     onChange={()=>handlePainManage("1")}/>
                                                                    <label className="form-check-label" for="Medications">
                                                                        Medications
                                                                    </label>
                                                                    <div className="last-dose d-flex align-item-center">
                                                                        <label>Type and Dosage:</label>
                                                                        <div className="last-dose w-md-50">
                                                                            <input type="text" className=""
                                                                            value={pain_management_interventions_notes}
                                                                             onChange={(e)=>setPain_management_interventions_notes(e.target.value)}
                                                                            readOnly={painManageRead}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Physical-Therapy" id="Physical-Therapy" value="Physical-Therapy" checked={pain_management_interventions == "2"?'checked':''} onChange={()=>handlePainManage("2")}/>
                                                                    <label className="form-check-label" for="Physical-Therapy">
                                                                        Physical Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Massage-Therapy" id="Massage-Therapy" value="Massage-Therapy" checked={pain_management_interventions == "3"?'checked':''} onChange={()=>handlePainManage("3")}/>
                                                                    <label className="form-check-label" for="Massage-Therapy">
                                                                    Massage Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Hot-Cold-Therapy" id="Hot-Cold-Therapy" value="Hot-Cold-Therapy" checked={pain_management_interventions == "4"?'checked':''} onChange={()=>handlePainManage("4")}/>
                                                                    <label className="form-check-label" for="Hot-Cold-Therapy">
                                                                        Hot/Cold Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" checked={pain_management_interventions == "5"?'checked':''} onChange={()=>handlePainManage("5")}/>
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" className="border-0 border-bottom border-dark" value={pain_management_interventions_notes1}
                                                                         onChange={(e)=>setPain_management_interventions_notes1(e.target.value)}
                                                                        readOnly={painManageRead1}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Current mt-3">
                                                    <p>9. Pain Management Related to Mobility</p>
                                                    <ul className="ps-5">
                                                        <li className="mb-2">
                                                            <label htmlFor="">Current Physical Therapy :</label>
                                                        </li>
                                                        <div className="Current-inner">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="Yes" id="Yes" value="Yes" checked={current_physical == "1"?'checked':''} onChange={e=>setCurrent_physical("1")}/>
                                                                <label className="form-check-label" for="Yes">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="No" id="No" value="No" checked={current_physical == "2"?'checked':''} onChange={e=>setCurrent_physical("2")}/>
                                                                <label className="form-check-label" for="Mild-Pain">
                                                                    No
                                                                </label>
                                                            </div>

                                                            <div className="d-md-flex align-item-ceter mt-2">
                                                                <label>If Yes, Frequency:</label>
                                                                <div className="last-dose w-md-50">
                                                                    <input type="text" className="" value={current_physical_notes} onChange={(e)=>setCurrent_physical_notes(e.target.value)} />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </ul>
                                                    <div className="Pain-Management">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Pain Management Interventions (Check all that apply):</label>
                                                            </li>
                                                            <div className="Pain-Management-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Medications" id="Medications"
                                                                     value="Medications" checked={pain_management == "1"?'checked':''} onChange={()=>handlePainManage1("1")} />
                                                                    <label className="form-check-label" for="Medications">
                                                                        Medications
                                                                    </label>
                                                                    <div className="last-dose d-flex align-item-center">
                                                                        <label>Type and Dosage:</label>
                                                                        <div className="last-dose w-md-50">
                                                                            <input type="text" className="" value={pain_management_notes} 
                                                                            onChange={(e)=>setPain_management_notes(e.target.value)} readOnly={painManageRead2}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Physical-Therapy" id="Physical-Therapy"
                                                                     value="Physical-Therapy" checked={pain_management == "2"?'checked':''} onChange={()=>handlePainManage1("2")}/>
                                                                    <label className="form-check-label" for="Physical-Therapy">
                                                                        Physical Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Massage-Therapy" id="Massage-Therapy"
                                                                     value="Massage-Therapy" checked={pain_management == "3"?'checked':''} onChange={()=>handlePainManage1("3")}/>
                                                                    <label className="form-check-label" for="Massage-Therapy">
                                                                    Massage Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Hot-Cold-Therapy" id="Hot-Cold-Therapy"
                                                                     value="Hot-Cold-Therapy" checked={pain_management == "4"?'checked':''}
                                                                      onChange={()=>handlePainManage1("4")}/>
                                                                    <label className="form-check-label" for="Hot-Cold-Therapy">
                                                                        Hot/Cold Therapy
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" checked={pain_management == "5"?'checked':''}
                                                                     onChange={()=>handlePainManage1("5")}/>
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" className="border-0 border-bottom border-dark" value={pain_management_notes1}
                                                                         onChange={(e)=>setPain_management_notes1(e.target.value)} readOnly={painManageRead3}/>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-md-6">
                                                                        <div class="input-field">
                                                                            <label for="">Therapist Name</label>
                                                                            <input type="text" placeholder="value" value={therapist_name} onChange={(e)=>setTherapist_name(e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="input-field">
                                                                            <label for="">Contact Info</label>
                                                                            <input type="text" placeholder="value" value={contact_info} onChange={(e)=>setContact_info(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Goals">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Goals of Therapy:</label>
                                                            </li>
                                                            <div className="Goals-inner">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Strength-Building" id="Strength-Building" value="Strength-Building" checked={goals_of_therapy == "1"?'checked':''} onChange={()=>handleGoalsTherapy("1")}/>
                                                                    <label className="form-check-label" for="Strength-Building">
                                                                        Strength Building
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Flexibility" id="Flexibility" value="Flexibility" checked={goals_of_therapy == "2"?'checked':''} onChange={()=>handleGoalsTherapy("2")}/>
                                                                    <label className="form-check-label" for="Flexibility">
                                                                        Flexibility and Range of Motion
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Gait-Improvement" id="Gait-Improvement" value="Gait-Improvement" checked={goals_of_therapy == "3"?'checked':''} onChange={()=>handleGoalsTherapy("3")}/>
                                                                    <label className="form-check-label" for="Gait-Improvement">
                                                                        Gait Improvement
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Pain-Reduction" id="Pain-Reduction" value="Pain-Reduction" checked={goals_of_therapy == "4"?'checked':''} onChange={()=>handleGoalsTherapy("4")}/>
                                                                    <label className="form-check-label" for="Pain-Reduction">
                                                                        Pain Reduction
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fall-Prevention" id="Fall-Prevention" value="Fall-Prevention" checked={goals_of_therapy == "5"?'checked':''} onChange={()=>handleGoalsTherapy("5")}/>
                                                                    <label className="form-check-label" for="Fall-Prevention">
                                                                        Fall Prevention
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <input className="form-check-input" type="checkbox" checked={goals_of_therapy == "6"?'checked':''} onChange={()=>handleGoalsTherapy("6")}/>
                                                                    <div class=" d-flex align-items-center ms-2">
                                                                        <label className="form-check-label" for="other">Other</label>
                                                                        <input type="text" className="border-0 border-bottom border-dark" value={goals_of_therapy_notes} onChange={(e)=>setGoals_of_therapy_notes(e.target.value)} readOnly={goalsRead} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="Current mt-3">
                                                    <p>10. Additional Notes on Mobility</p>
                                                    <ul className="ps-5 pb-4 border-bottom">
                                                        <li className="mb-2">
                                                            <label htmlFor="" className="d-block">Specific Concerns or Needs Related to Mobility (e.g., exercises, limitations, preferences)</label>
                                                            <div className="last-dose mb-0">
                                                                <input className="border-none " type="text" placeholder="Value" value={specific_concerns} onChange={(e)=>setSpecific_concerns(e.target.value)}/>
                                                            </div>
                                                        </li>


                                                    </ul>
                                                    <div className="Self-Care border-bottom pb-4">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Self-Care Abilities</label>
                                                            </li>
                                                            <div className="Self-Care-inner">
                                                                <div className="Bathing">
                                                                    <span>1. Bathing</span>

                                                                    <div className="bathing-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={bathing == "1"?'checked':''} onChange={()=>handleBathing("1")}/>
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" checked={bathing == "2"?'checked':''} onChange={()=>handleBathing("2")}/>
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" checked={bathing == "3"?'checked':''} onChange={()=>handleBathing("3")}/>
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" checked={bathing == "4"?'checked':''} onChange={()=>handleBathing("4")}/>
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions/Preferences</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" value={bathing_notes} onChange={(e)=>setBathing_notes(e.target.value)} readOnly={bathingRead} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Dressing mt-2">
                                                                    <span>2. Dressing</span>

                                                                    <div className="Dressing-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={dressing == "1"?'checked':''} onChange={()=>handleDressing("1")}/>
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent 
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" checked={dressing == "2"?'checked':''}  onChange={()=>handleDressing("2")}/>
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" checked={dressing == "3"?'checked':''}  onChange={()=>handleDressing("3")}/>
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" checked={dressing == "4"?'checked':''}  onChange={()=>handleDressing("4")}/>
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions/Preferences</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" value={dressing_notes} onChange={(e)=>setDressing_notes(e.target.value)} readOnly={dressingRead} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Eating mt-2">
                                                                    <span>3. Eating</span>

                                                                    <div className="Eating-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={eating == "1"?'checked':''} onChange={()=>handleEating("1")}/>
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" checked={eating == "2"?'checked':''} onChange={()=>handleEating("2")}/>
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" checked={eating == "3"?'checked':''} onChange={()=>handleEating("3")}/>
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" checked={eating == "4"?'checked':''} onChange={()=>handleEating("4")}/>
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions (e.g., soft foods, assistance cutting food)</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" value={eating_notes} onChange={(e)=>setEating_notes(e.target.value)} readOnly={eatingRead}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Grooming mt-2">
                                                                    <span>4. Grooming</span>

                                                                    <div className="Grooming-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={grooming == "1"?'checked':''} onChange={()=>handleGrooming("1")}/>
                                                                            <label className="form-check-label" for="Independent">
                                                                                Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-supervision" id="Needs-supervision" value="Needs-supervision" checked={grooming == "2"?'checked':''} onChange={()=>handleGrooming("2")}/>
                                                                            <label className="form-check-label" for="Needs-supervision">
                                                                                Needs supervision
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Full-assistance" id="Full-assistance" value="Full-assistance" checked={grooming == "3"?'checked':''} onChange={()=>handleGrooming("3")}/>
                                                                            <label className="form-check-label" for="Full-assistance">
                                                                                Full assistance
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" checked={grooming == "4"?'checked':''} onChange={()=>handleGrooming("4")}/>
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Special Instructions/Preferences</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" value={grooming_notes} onChange={(e)=>setGrooming_notes(e.target.value)} readOnly={groomingRead}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Continence border-bottom py-3">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Continence</label>
                                                            </li>

                                                            <span>1. Bladder Control</span>
                                                            <div className="Goals-inner mt-2">

                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Fully-Continent" id="Fully-Continent" value="Fully-Continent" checked={bladder_control == "1"?'checked':''} onChange={e=>setBladder_control("1")}/>
                                                                    <label className="form-check-label" for="Fully-Continent">
                                                                        Fully Continent
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Occasional" id="Occasional" value="Occasional" checked={bladder_control == "2"?'checked':''} onChange={e=>setBladder_control("2")}/>
                                                                    <label className="form-check-label" for="Occasional">
                                                                        Occasional Incontinence
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="Gait-Improvement" id="Gait-Improvement" value="Gait-Improvement" checked={bladder_control == "3"?'checked':''} onChange={e=>setBladder_control("3")}/>
                                                                    <label className="form-check-label" for="Gait-Improvement">
                                                                        Full Incontinence
                                                                    </label>
                                                                </div>

                                                                <div className="form-check">
                                                                   
                                                                    <label className="form-check-label" for="Fall-Prevention">
                                                                        Uses (Check all that apply)
                                                                    </label>
                                                                </div>
                                                                <div className="d-flex align-item-center">
                                                                    <div className="form-check me-2">
                                                                        <input className="form-check-input" type="checkbox" name="Pads" id="Pads" value="Pads" onChange={(e)=>handleBladderUses(e)} checked={uses.includes('Pads')} />
                                                                        <label className="form-check-label" for="Pads">
                                                                            Pads
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check me-2">
                                                                        <input className="form-check-input" type="checkbox" name="Ostomy-Bag" id=" Ostomy-Bag" value="Ostomy-Bag" onChange={(e)=>handleBladderUses(e)} checked={uses.includes('Ostomy-Bag')}/>
                                                                        <label className="form-check-label" for=" Ostomy-Bag">
                                                                            Ostomy Bag
                                                                        </label>
                                                                    </div>
                                                                    <div class="d-flex align-item-center">
                                                                        <input class="form-check-input" value="other" type="checkbox" onChange={(e)=>handleBladderUses(e)} checked={uses.includes('other')}/>
                                                                        <div class=" d-flex align-items-center ms-2">
                                                                            <label class="form-check-label" for="other">Other</label>
                                                                            <input type="text" class="border-0 border-bottom border-dark" value={uses_notes} onChange={(e)=>setUses_notes(e.target.value)} readOnly={bladderRead}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="Cognitive pt-3">
                                                        <ul className="ps-5">
                                                            <li className="mb-2">
                                                                <label htmlFor="">Cognitive Function</label>
                                                            </li>
                                                            <div className="Cognitive-inner">
                                                                <div className="Memory-Issues">
                                                                    <span>1. Memory Issues</span>

                                                                    <div className="Memory-Issues-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="None" id="None" value="None" checked={memory_issue == "1"?'checked':''} onChange={e=>setMemory_issue("1")} />
                                                                            <label className="form-check-label" for="None">
                                                                                None
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Mild" id="Mild" value="Mild" checked={memory_issue == "2"?'checked':''} onChange={e=>setMemory_issue("2")}/>
                                                                            <label className="form-check-label" for="Mild">
                                                                                Mild
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Moderate" id="Moderate" value="Moderate" checked={memory_issue == "3"?'checked':''} onChange={e=>setMemory_issue("3")}/>
                                                                            <label className="form-check-label" for="Moderate">
                                                                                Moderate
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Severe" id="Severe" value="Severe" checked={memory_issue == "4"?'checked':''} onChange={e=>setMemory_issue("4")}/>
                                                                            <label className="form-check-label" for="Severe">
                                                                                Severe
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Orientation mt-2">
                                                                    <span>2. Orientation</span>

                                                                    <div className="Orientation-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Fully-Oriented" id="Fully-Oriented" value="Fully-Oriented" checked={orientation == "1"?'checked':''} onChange={e=>setOrientation("1")}/>
                                                                            <label className="form-check-label" for="Fully-Oriented">
                                                                                Fully Oriented
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Occasionally" id="Occasionally" value="Occasionally" checked={orientation == "2"?'checked':''} onChange={e=>setOrientation("2")}/>
                                                                            <label className="form-check-label" for="Occasionally">
                                                                                Occasionally Disoriented
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Always-Disoriented" id="Always-Disoriented" value="Always-Disoriented" checked={orientation == "3"?'checked':''} onChange={e=>setOrientation("3")}/>
                                                                            <label className="form-check-label" for="Always-Disoriented">
                                                                                Always Disoriented
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="Eating mt-2">
                                                                    <span>3. Decision-Making Ability</span>

                                                                    <div className="Eating-inner mt-2">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Independent" id="Independent" value="Independent" checked={decision_making == "1"?'checked':''} onChange={()=>handleDecision("1")}/>
                                                                            <label className="form-check-label" for="Independent">
                                                                                Fully Independent
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Needs-Cueing" id="Needs-Cueing"  value="Independent" checked={decision_making == "2"?'checked':''} onChange={()=>handleDecision("2")}/>
                                                                            <label className="form-check-label" for="Needs-Cueing">
                                                                                Needs Cueing
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="Dependent" id="Dependent" value="Dependent"  checked={decision_making == "3"?'checked':''} onChange={()=>handleDecision("3")} />
                                                                            <label className="form-check-label" for="Dependent">
                                                                                Dependent
                                                                            </label>
                                                                        </div>
                                                                        <div className="d-flex align-item-center">
                                                                            <input className="form-check-input" type="checkbox" value="Independent" checked={decision_making == "4"?'checked':''} onChange={()=>handleDecision("4")}/>
                                                                            <div class=" d-flex align-items-center ms-2">
                                                                                <label className="form-check-label" for="other">Notes on Cognitive Function</label>
                                                                                <input type="text" className="border-0 border-bottom border-dark" value={decision_making_notes} onChange={(e)=>setDecision_making_notes(e.target.value)} readOnly={decisionRead}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                              
                                                <SaveButton setLoading={setLoading} loading={loading}/>
                                                </form>
                                            </div>
                                        </div>
        </>
    )
}
export default FunctionalAbility1;