import React,{useState} from 'react';
import { Link } from "react-router-dom";
import './login.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";
import TeamLogin from "../../../Service/Team/Login/LoginService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../Loader/Loading';
export default function Login(){
    const navigate = useNavigate();
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [loading,setLoading] = useState(false)

    const sumbitLogin = async (e)=>{
        e.preventDefault();
        setLoading(true)
        if(validate()){
            let post = {email:email,password:password};
            const apicall = await TeamLogin.TeamLogin(post)
            if (apicall.state === true) {
                apicall.result.json().then((resp1) => {
                if (resp1.status == 200) {
                    localStorage.setItem("user", JSON.stringify(resp1.token));
                    if(resp1.details.userType == 'Nurse')
                    {
                        setLoading(false)
                        navigate('admin/nurse/resident-health-data')
                    }
                    else if(resp1.details.userType == 'Admin')
                    {
                        setLoading(false)
                        navigate('admin/carehome')
                    }
                    else if(resp1.details.userType == 'Doctor')
                    {
                            setLoading(false)
                            navigate('doctor')
                    }
                    
                }
                });
            } else {
                apicall.result.json().then((resp1) => {
                    if (resp1.status === 400) {
                        toast(resp1.error)
                    } 
                    setLoading(false)
                });
            }
        }
    }
    const validate = ()=>{
        let result = true;
        if(email === ''){
            toast("Email field is required")
            result = false;
            setLoading(false)
        }
        if(password === ''){
            toast("Password field is required")
            result = false;
            setLoading(false)
        }
        
        return result;
    }
    return(
    <div className="sign-up">
        <div className="container-lg">
            <div className="row">
                <div className="col-md-6">
                    {/* <div className="sign-up-image">
                        <div className="sign-up-overlay">
                            <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div> */}
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="sign-up-section">
                        <div className="sign-up-wellcome">
                            <img src={Logo} alt="Logo"/>
                            <h3>Login</h3>
                            <p>Enter your details to login</p>
                        </div>
                        <div className="sign-up-field">
                           <form _lpchecked="1" onSubmit={sumbitLogin}>
                           <div className="input-field">
                                <label for="">Email</label>
                                <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email"/>
                            </div>
                            <div className="input-field">
                                <label for="">Password</label>
                                <input type="Password" value={password} onChange={(e)=>setPassword(e.target.value)}  placeholder="Password"/>
                            </div> 
                           
                            <div className="input-field mt-3">
                                <button onClick={sumbitLogin} className='{`${loading ? "disabled" : ""}`}'>
                                    {
                                        loading ? (
                                            <>
                                            <Loading />
                                          </>
                                        ):"Login"
                                    }
                                </button>
                                <ToastContainer />
                                
                            </div> 
                            </form>
                            
                                <span>Don't remember your password? <Link
                                to="/forgot-password"
                                style={{ color: "#FFFFFF", textDecoration: "none" }}
                                ><a href="javascript:void(0)">Forgot Password</a></Link></span>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}