import React,{useEffect, useState} from 'react'
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OfficeUseOnly({setTab,setRecipientUserId,recipientUserId,userDetails,setSuccessMessage}) {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState([{ roleId: "",userEmail:""}])

  let addMore = () => {
    setFormValues([...formValues, { roleId: "",userEmail:""}])
  }
  
  let handleChange = async (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    if(e.target.name == 'roleId'){
      var roleId = e.target.value;
      const apicall1 = await LoginService.RoleByUser(auth,roleId)
      if(apicall1.state === true){
          apicall1.result.json().then((resp)=>{
            setUser(resp.data)
          })
      }else
      {
          apicall1.result.json().then((resp)=>{
            
          })
      }
    }
  }
  let handleDelete = (i)=>{
    const del = [...formValues];
    del.splice(i,1);
    setFormValues(del)
  }
  const auth = JSON.parse(localStorage.getItem("user"));
  const [loading,setLoading] = useState(false);
  const [residentSignature,setresidentSignature] = useState(userDetails.useOffice?userDetails.useOffice.residentSignature:'');
  const [roomNumber,setroomNumber] = useState(userDetails.useOffice?userDetails.useOffice.roomNumber:'');
  const [dateOfAdmission,setdateOfAdmission] = useState(userDetails.useOffice?userDetails.useOffice.dateOfAdmission:'');
  const [assignedCare,setassignedCare] = useState(userDetails.useOffice?userDetails.useOffice.assignedCare:'');
  const [careLevel,setcareLevel] = useState(userDetails.useOffice?userDetails.useOffice.careLevel:'');
  const [specialCare,setspecialCare] = useState(userDetails.useOffice?userDetails.useOffice.specialCare:'');
  const [additionNotes,setadditionNotes] = useState(userDetails.useOffice?userDetails.useOffice.additionNotes:'');
  const [role,setRole] = useState([])
  const [user,setUser] = useState([])
  const [list,setList] = useState(userDetails.useOffice?userDetails.useOffice.teamMember?userDetails.useOffice.teamMember:[]:[]);
  
  useEffect(()=>{
    getRoles();
  },[])
  const getRoles = async ()=>{
    const apicall = await LoginService.Role(auth)
    if(apicall.state === true){
        apicall.result.json().then((resp)=>{
          setRole(resp.data)
          // setTab(resp.tab)
          
        })
    }else
    {
        apicall.result.json().then((resp)=>{
            console.log("failed");
        })
    }
  }
  const handleRole = async (e)=>{
    var roleId = e.target.value;
    const apicall1 = await LoginService.RoleByUser(auth,roleId)
    if(apicall1.state === true){
        apicall1.result.json().then((resp)=>{
           setUser(resp.data)
        })
    }else
    {
        apicall1.result.json().then((resp)=>{
           
        })
    }
  }
  
  const officeUseStore = async (e)=>{
    e.preventDefault();
    
   
    var formData = new FormData();
    formData.append("recipientUserId",recipientUserId);
    formData.append("residentSignature",residentSignature);
    formData.append("roomNumber",roomNumber);
    formData.append("dateOfAdmission",dateOfAdmission);
    formData.append("assignedCare",assignedCare);
    formData.append("careLevel",careLevel);
    formData.append("specialCare",specialCare);
    formData.append("additionNotes",additionNotes);
    formValues.map((item)=>{
      if(item.roleId != ""){
        formData.append("roleId[]", item.roleId);
      }
      if(item.userEmail != ""){
        formData.append("userEmail[]", item.userEmail);
      } 
      
  })
   
        setLoading(true)
        const apicall = await LoginService.OfficeUse(auth,formData)
        if(apicall.state === true){
            apicall.result.json().then((resp)=>{
                setLoading(false)
                setSuccessMessage(resp.message)
                // toast("Registration saved successfuly")
                setTab("")
                navigate('/admin/patient-list')
            })
        }else
        {
            apicall.result.json().then((resp)=>{
                console.log("failed");
                setLoading(false)
            })
        }
  }
  return (
    <>
       
        <h5>For Office use Only</h5>
        <form onSubmit={officeUseStore}>
        <div className="forms ps-0 officeuse-box">
           
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Resident/Guardian Signature:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" value={residentSignature} onChange={(e)=>setresidentSignature(e.target.value)} />
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Date of Admission:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" value={dateOfAdmission} onChange={(e)=>setdateOfAdmission(e.target.value)}/>
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Room Number:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" value={roomNumber} onChange={(e)=>setroomNumber(e.target.value)}/>
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Assigned Care Coordinator:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" value={assignedCare} onChange={(e)=>setassignedCare(e.target.value)}/>
                </div>
            </div>
            <div className="d-sm-flex gap-3 align-items-center">
              <div>
                <label className="form-check-label" for="Nap-Habits">
                  <ul className="mb-2 mt-3 mt-sm-0">
                    <li>
                    Care Level Required:
                    </li>
                  </ul>
                </label>
              </div>
              <div className="options mt-sm-0 mt-4 mt-sm-0">
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="yes"
                    checked={careLevel == '1'}
                    onChange={(e)=>setcareLevel('1')}
                  />
                  Independent
                </label>
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="yes"
                    checked={careLevel == '2'}
                    onChange={(e)=>setcareLevel('2')}
                  />
                  Assisted Living
                </label>
                <label>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name="flu"
                    value="no"
                    checked={careLevel == '3'}
                    onChange={(e)=>setcareLevel('3')}
                  />
                  Memory Care
                </label>
              </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Special Care Instructions:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" value={specialCare} onChange={(e)=>setspecialCare(e.target.value)}/>
                </div>
            </div>
            <div className="mt-4 mt-sm-0 d-md-flex">
                <div className="">
                <ul>
                    <li>Additional Notes:</li>
                </ul>
                </div>
                <div className="last-dose w-30">
                <input type="text" className="border-bottom-5 mt-2 mt-sm-0 mt-sm-0" value={additionNotes} onChange={(e)=>setadditionNotes(e.target.value)}/>
                </div>
            </div>
            
        </div>
       
        <h5>Add Team Member</h5> 
          {
            formValues.map((element,index)=>(
              <>
              {
                index==0?<>
               <div className="forms row">
                  <div className="col-md-3">
                      <div className="input-field">
                        <label for="">Role</label>
                        <select name="roleId" className='form-control' value={element.roleId || ""} onChange={e => handleChange(index, e)} id="">
                            <option value="">Select</option>
                              {role.map((item)=>(
                              <>
                                <option value={item.id}>{item.name}</option>
                              </>
                            ))}
                          </select>
                      </div>
                  </div>
                  <div className="col-md-3">
                      <div className="input-field">
                        <label for="">User Email</label>
                          <select name="userEmail" className='form-control' value={element.userEmail || ""} onChange={e => handleChange(index, e)} id="">
                            <option value="">Select User</option>
                              {user.map((item)=>(
                              <>
                                <option value={item.email}>{item.email}</option>
                              </>
                            ))}
                          </select>
                      </div>
                  </div>
                  <div className="col-md-3">
                      <div className="input-field">
                        <a href="javascript:void(0)" onClick={()=>addMore()}>Add More</a>
                      </div>
                  </div>
                </div>
                </>:<>
                <div className="forms row">
                  <div className="col-md-3">
                      <div className="input-field">
                        <label for="">Role</label>
                        <select name="roleId" className='form-control' value={element.roleId || ""} onChange={e => handleChange(index, e)} id="">
                            <option value="">Select</option>
                              {role.map((item)=>(
                              <>
                                <option value={item.id}>{item.name}</option>
                              </>
                            ))}
                          </select>
                      </div>
                  </div>
                  <div className="col-md-3">
                      <div className="input-field">
                        <label for="">User Email</label>
                          <select name="userEmail" className='form-control' value={element.userEmail || ""} onChange={e => handleChange(index, e)} id="">
                            <option value="">Select User</option>
                              {user.map((item)=>(
                              <>
                                <option value={item.email}>{item.email}</option>
                              </>
                            ))}
                          </select>
                      </div>
                  </div>
                  <div className="col-md-3">
                      <div className="input-field">
                        <a href="javascript:void(0)" onClick={()=>handleDelete(index)}>Remove</a>
                      </div>
                  </div>
                </div>
                </>
               }
              </>
            ))
          }
       
        <div className="forms ps-0 officeuse-box ">
            {list.length>0?(<>
              <div className="row">
                <div className="col-md-12">
                    <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <td>Sr.no</td>
                            <td>Email</td>
                            <td>Role</td>                               
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>   
                      {
                        list.map((item,index)=>(
                          <>
                          <tr>
                            <td>{++index}</td>
                            <td>{item.email}</td>
                            <td>{item.roleType}</td>
                            <td><i className="fa fa-trash"></i></td>
                          </tr>
                          </>
                        ))
                      }                                                                                    
                    </tbody>
                    </table>
                </div>
              </div>
            </>):""}
           
            <SaveButton setLoading={setLoading} loading={loading}/>
        </div>
        </form>
        <ToastContainer/>
    </>
  )
}
