import React, { useState } from "react";
import LoginService from "../../../Service/Team/Login/LoginService";
import SaveButton from "../Loader/SaveButton";

function SocialHistory({setTab,setRecipientUserId,recipientUserId,userDetails,setSuccessMessage}){
    const auth = JSON.parse(localStorage.getItem("user"));
    const [loading,setLoading] = useState(false)
    const [group_activity,setgroup_activity] = useState(userDetails.socialHistory?userDetails.socialHistory.group_activity:'')
    const [preferred_activity,setpreferred_activity] = useState(userDetails.socialHistory?userDetails.socialHistory.preferred_activity:[])
    const [preferred_activity_text,setpreferred_activity_text] = useState(userDetails.socialHistory?userDetails.socialHistory.preferred_activity_text:'')
    const [preferred_activity_textRead,setpreferred_activity_textRead] = useState("readOnly")
    const [hobbies,sethobbies] = useState(userDetails.socialHistory?userDetails.socialHistory.hobbies:'')
    const [previous_community,setprevious_community] = useState(userDetails.socialHistory?userDetails.socialHistory.previous_community:'')
    const [preferred_faith,setpreferred_faith] = useState(userDetails.socialHistory?userDetails.socialHistory.preferred_faith:'')
    const [special_observance,setspecial_observance] = useState(userDetails.socialHistory?userDetails.socialHistory.special_observance:'')
    const [preferred_frequency,setpreferred_frequency] = useState(userDetails.socialHistory?userDetails.socialHistory.preferred_frequency:'')
    const [best_time,setbest_time] = useState(userDetails.socialHistory?userDetails.socialHistory.best_time:'')
   
    const handlePreferred = (e)=>{
          const {value,checked} = e.target;
          if(checked){
            if(value == '10'){
                setpreferred_activity_textRead("")
            }else{
                // setpreferred_activity_textRead("readOnly")    
            }
            setpreferred_activity([...preferred_activity,value])
          }
          else{
            setpreferred_activity(preferred_activity.filter((e)=> e!== value))
            if(value == '10')
            {
              setpreferred_activity_textRead("readOnly")
              setpreferred_activity_text("");
            }
            
          }
    }
   
    const SocialPreferenceStore = async (e) =>{
        e.preventDefault();
        var formData = new FormData();
        formData.append("recipientUserId",recipientUserId);
        formData.append("group_activity",group_activity);
        formData.append("preferred_activity[]",preferred_activity);
        formData.append("preferred_activity_text",preferred_activity_text);
        formData.append("hobbies",hobbies);
        formData.append("previous_community",previous_community);
        formData.append("preferred_faith",preferred_faith);
        formData.append("special_observance",special_observance);
        formData.append("preferred_frequency",preferred_frequency);
        formData.append("best_time",best_time);
            setLoading(true)
            const apicall = await LoginService.SocialHis(auth,formData)
            if(apicall.state === true){
                apicall.result.json().then((resp)=>{
                    setTab(resp.tab)
                    setLoading(false)
                    setSuccessMessage(resp.message)

                })
            }else
            {
                apicall.result.json().then((resp)=>{
                    console.log("failed");
                    setLoading(false)
                })
            }
    }
    return(
        <>
        <h5>Social Preferences</h5>
                <div className="forms ps-0">
                      <form onSubmit={SocialPreferenceStore}>
                      <div className="">
                        <p>1. Group Activities</p>
                        <div className="ps-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="yes"
                              id="yes"
                              value="yes"
                              checked={group_activity == '1'?'checked':''}
                              onChange={(e)=>setgroup_activity('1')}
                            />
                            <label className="form-check-label" for="yes">
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="No"
                              id="No"
                              value="No"
                              checked={group_activity == '2'?'checked':''}
                              onChange={(e)=>setgroup_activity('2')}
                            />
                            <label className="form-check-label" for="None">
                              No
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Sometimes"
                              id="Sometimes"
                              value="Sometimes"
                              checked={group_activity == '3'?'checked':''}
                              onChange={(e)=>setgroup_activity('3')}
                            />
                            <label className="form-check-label" for="Sometimes">
                              Sometimes
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="my-4">
                        <p>2.Preferred Activities (Check all that apply)</p>
                        <div className="ps-4">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Reading"
                              id="Reading"
                              value="1"
                              checked={preferred_activity.includes("1")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Reading">
                              Reading
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Music"
                              id="Music"
                              value="2"
                              checked={preferred_activity.includes("2")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Music">
                              Music
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Gardening"
                              id="Gardening"
                              value="3"
                              checked={preferred_activity.includes("3")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Gardening">
                              Gardening
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Board-Card-Games"
                              id="Board-Card-Games"
                              value="4"
                              checked={preferred_activity.includes("4")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label
                              className="form-check-label"
                              for="Board-Card-Games"
                            >
                              Movies
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Board-Card-Games"
                              id="Board-Card-Games"
                              value="5"
                              checked={preferred_activity.includes("5")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label
                              className="form-check-label"
                              for="Board-Card-Games"
                            >
                              Board/Card Games
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Other"
                              id="Walking"
                              value="6"
                              checked={preferred_activity.includes("6")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Walking">
                              Walking
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Yoga"
                              id="Yoga"
                              value="7"
                              checked={preferred_activity.includes("7")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Yoga">
                              Yoga/Exercise
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Religious-Services"
                              id="Religious-Services"
                              value="8"
                              checked={preferred_activity.includes("8")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label
                              className="form-check-label"
                              for="Religious-Services"
                            >
                              Religious Services
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Crafts"
                              id="Crafts"
                              value="9"
                              checked={preferred_activity.includes("9")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Crafts">
                              Crafts
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Other"
                              id="Other"
                              value="10"
                              checked={preferred_activity.includes("10")}
                              onChange={(e)=>handlePreferred(e)}
                            />
                            <label className="form-check-label" for="Other">
                              Other{" "}
                              
                            </label>
                            <div className="last-dose w-100 flex-1">
                                <input
                                    type="text"
                                    className="border-bottom-5 w-100"
                                    value={preferred_activity_text}
                                    onChange={(e)=>setpreferred_activity_text(e.target.value)}
                                    readOnly={preferred_activity_textRead}
                                />
                                </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-md-flex">
                        <div className="">
                          <p>3. Hobbies and Interests</p>
                        </div>
                        <div className="last-dose w-100 flex-1">
                          <input
                            type="text"
                            className="border-bottom-5 w-100"
                            value={hobbies}
                            onChange={(e)=>sethobbies(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-3 d-md-flex">
                        <div className="">
                          <p>
                            4. Previous Community Involvement (e.g., clubs,
                            organizations):
                          </p>
                        </div>
                        <div className="last-dose w-50">
                          <input
                            type="text"
                            className="border-bottom-5 w-100"
                            onChange={(e)=>setprevious_community(e.target.value)}
                            value={previous_community}
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <p>5.Religious Practices/Services:</p>
                        <ul className="d-flex ps-5">
                          <li>
                            <label>Preferred Faith-Based Activities</label>
                          </li>
                          <div className="last-dose w-50">
                            <input
                              type="text"
                              className="border-bottom-5 w-100"
                              onChange={(e)=>setpreferred_faith(e.target.value)}
                              value={preferred_faith}
                            />
                          </div>
                        </ul>
                        <ul className="d-flex ps-5">
                          <li>
                            <label>Special Observances</label>
                          </li>
                          <div className="last-dose w-50">
                            <input
                              type="text"
                              className="border-bottom-5 w-100"
                              onChange={(e)=>setspecial_observance(e.target.value)}
                              value={special_observance}
                            />
                          </div>
                        </ul>
                      </div>
                      <div className="mt-3">
                        <p>6.Family Visitation Preferences</p>

                        <ul className="ps-5">
                          <li>
                            <label>Preferred Frequency</label>
                          </li>
                        </ul>
                        <div className=" mt-0 ps-4 mb-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="None"
                              id="None"
                              value="None"
                              checked={preferred_frequency == '1'?'checked':''}
                              onChange={(e)=>setpreferred_frequency("1")}
                            />
                            <label className="form-check-label" for="Other">
                              Daily{" "}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Weekly"
                              id="Weekly"
                              value="Weekly"
                              checked={preferred_frequency == '2'?'checked':''}
                              onChange={(e)=>setpreferred_frequency("2")}
                            />
                            <label className="form-check-label" for="Weekly">
                              Weekly{" "}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Monthly"
                              id="Monthly"
                              value="Monthly"
                              checked={preferred_frequency == '3'?'checked':''}
                              onChange={(e)=>setpreferred_frequency("3")}
                            />
                            <label className="form-check-label" for="Monthly">
                              Monthly{" "}
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="Special-Occasions-Only"
                              id="Special-Occasions-Only"
                              value="Special-Occasions-Only"
                              checked={preferred_frequency == '4'?'checked':''}
                              onChange={(e)=>setpreferred_frequency("4")}
                            />
                            <label
                              className="form-check-label"
                              for="Special-Occasions-Only"
                            >
                              Special Occasions Only{" "}
                            </label>
                          </div>
                        </div>
                        <ul className="d-flex ps-5">
                          <li>
                            <label>Best Times for Visits:</label>
                          </li>
                          <div className="last-dose w-50">
                            <input
                              type="text"
                              className="border-bottom-5 w-100"
                              value={best_time}
                              onChange={(e)=>setbest_time(e.target.value)}
                            />
                          </div>
                        </ul>
                      </div>
                      <SaveButton setLoading={setLoading} loading={loading}/>
                      </form>
                </div>                               
        </>
    )
}
export default SocialHistory