import React, { useEffect, useState } from 'react';
import './Login.css';
import Logo from '../../../images/endwelllcare-logo.png';
import LoginImg from '../../../images/sign-up.jpeg';
import { useNavigate } from "react-router-dom";
import Service from '../../../Service/Team/Famaly/Services';
import Loading from '../../Admin/Loader/Loading';
import { isLoggedInMember } from '../../../auth';
export default function FamilyLogin() {
    const navigate = useNavigate();
    const [formData,setFormData] = useState({email:''});
    const [error,serError] = useState("");
    const [loading,setLoading] = useState(false);
    
    const memberLogin = async (e)=>{
        e.preventDefault();
        if(validate())
        {
            setLoading(true)
            const memberLogin = await Service.FamilyLogin(formData)
            if(memberLogin.state === true)
            {
                memberLogin.result.json().then((resp) => {
                    navigate('/family/otp')
                    setLoading(false)
                    localStorage.setItem("memberEmail",formData.email)
                });
            }else{
                memberLogin.result.json().then((resp) => {
                    serError(resp.error.email[0]);
                    setLoading(false)
                });
            }
        }
    }

    const validate = ()=>{
        let result = true
        if(!formData.email)
        {
            serError("Email is required");
            return result = false;
        }
        return result; 
    }

    useEffect(()=>{
        if(isLoggedInMember())
        {
            navigate('/family/home');
        }
    },[isLoggedInMember])
  return (
    <div className="sign-up family-login-main">
        <div className="container-lg">
            <div className="row">
                <div className="col-md-6">
                    {/* <div className="sign-up-image">
                        <div className="sign-up-overlay">
                            <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div> */}
                    <div className='left-img position-relative'>
                        <img src={LoginImg} alt='left img'/>
                        <div className='left-img-text'>
                        <h2>Welcome to TRACKER.HEALTH</h2>
                            <p>Join us as we empower families and caregivers to provide seamless
                                and compassionate care</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="sign-up-section">
                            <div className="sign-up-wellcome">
                                <img src={Logo}  alt=""/>
                                <h3>Login</h3>
                                <p>Enter your details to login</p>
                            </div>
                            <div className="sign-up-field">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                            aria-selected="true">Email*</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                            aria-selected="false">Number*</button>
                                    </li>
                                </ul>
                                <div className="tab-content mt-4" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <form action="" onSubmit={memberLogin}>

                                            <div className="input-field">
                                                <label for="">Email </label>
                                                <small className='text-danger'>{error}</small>
                                                <input type="text" placeholder="Enter your name"
                                                value={formData.email}
                                                onChange={(e)=>setFormData({...formData,email:e.target.value})}
                                                />
                                            </div>
                                            <div className="input-field mt-3">
                                            <button type="submit" className='{`${loading ? "disabled" : ""}`}'>
                                                {
                                                    loading ? (
                                                        <>
                                                        <Loading />
                                                    </>
                                                    ):"Send OTP"
                                                }
                                            </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="input-field">
                                            <label for="">Number*</label>
                                            <input type="text" placeholder="Enter your name"/>
                                        </div>
                                        <div className="input-field mt-3">
                                            <button type="submit" onClick={() => navigate('/family/otp')}>Send OTP</button>
                                        </div>
                                    </div>
                                </div>
                                {/* <span>Don’t have an account?<a href="#"> Sign Up</a></span> */}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
