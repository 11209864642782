import React from 'react';
import './FamilyHome.css';
import FamilyDashboard from '../Dashboard/Dashboard';
import MyCalendar from '../../Calendar/Calendar';
import { BarChart } from '@mui/x-charts/BarChart';

const pData = [140, 135, 120, 160, 131, 142, 105];
const uData = [89, 64, 75, 85, 73, 68, 55];

const xLabels = [
    'Aug-16',
    'Aug-17',
    'Aug-18',
    'Aug-19',
    'Aug-20',
    'Aug-21',
    'Aug-22',
];

export default function FamilyHome() {
    let auth = JSON.parse(localStorage.getItem('familyDetails'));
   
    return (
        <>
            <FamilyDashboard />
            <div className="main-content family-home">
                <div className="container-fluid p-0 m-0">
                    <div className="home">
                        <div className='row mb-4'>
                            <div className='col-md-8'>
                                <div className="welcome">
                                    <div className="container">
                                        <div className="wellcome-txt">
                                            <h6>Good Morning,{auth?.fullName}</h6>
                                            <p>Take a look at the latest update for your father</p>
                                        </div>
                                        <div className="welcome-image">
                                            <img src="/images/oldman.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row g-3 mt-2'>
                                    <div className='col-md-5'>
                                        <div className="Patients">
                                            <div className="icon">
                                                <img src="/images/blood.svg" alt="" />
                                            </div>
                                            <div className="content">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-md-8 col-sm-9">
                                                            <div className="content-txt">
                                                                <h6>Blood Pressure</h6>
                                                                <h5>180/120 <span>mmHg</span></h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-3">
                                                            <div className="Blood-Pressure-graph">
                                                                <img src="./img/blood-pressure-graph.svg" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="blood-pressure-warning">
                                                                <p><img src='/images/caution.svg' />
                                                                    <span>Caution : High BP</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='h-100'>
                                        <div className=" Patients Walking mb-3">
                                            <div className="icon">
                                                <img src="/images/out-door.png" alt="" />
                                            </div>
                                            <div className="content-txt">
                                                <h6>Outdoor Walking</h6>
                                                <h5>4,050 <span>Steps</span></h5>
                                            </div>
                                        </div>
                                        <div className=" Patients Walking">
                                            <div className="icon">
                                                <img src="/images/happy.png" alt="" />
                                            </div>
                                            <div className="content-txt">
                                                <h6>Emotional Status</h6>
                                                <h5>Happy</h5>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="Patients">
                                            <div className="icon">
                                                <img src="/images/sleep.png" alt="" />
                                            </div>
                                            <div className="content">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="content-txt">
                                                                <h6>sleep cycle</h6>
                                                                <h5>8hr 30 mins</h5>
                                                            </div>
                                                            <div className='img-sleep'>
                                                                <img src='/images/sleep-img.svg' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="Blood-Pressure-graph">

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <MyCalendar/>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-5'>
                                <div className='chart-box'>
                                    <div className='chart-box-top'>
                                        <h3>Health Metrics Summary</h3>
                                        <div className='chart-options-box'>
                                            <div class="input-field"><select><option value="">BP</option></select></div>
                                        </div>
                                    </div>
                                    <BarChart
                                        borderRadius={100}
                                        width={500}
                                        height={300}
                                        series={[
                                            { data: pData, label: 'systolic pressure', id: 'pvId',color: '#FAA600' },
                                            { data: uData, label: 'diastolic pressure', id: 'uvId',color: '#FEDFA8' },
                                        ]}
                                        xAxis={[{ data: xLabels, scaleType: 'band' }]}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="medication-container">
                                    <div className="header">
                                        <div>
                                            <h2>Medications</h2>
                                            <p>Your father’s medication timeline</p>
                                        </div>
                                        <div> <a href="#">View all</a></div>
                                    </div>
                                    <div className="timeline">
                                        <div className="timeline-item">
                                            <div className="timeline-inner">
                                                <div className="time">09:00am</div>
                                                <div className="medication">Fluoxetine 20mg</div>
                                            </div>
                                            <div className="dose">Morning dose</div>
                                        </div>
                                        <div className="timeline-item">
                                            <div className="timeline-inner">
                                                <div className="time">12:00am</div>
                                                <div className="medication">Paracetamol 20mg</div>
                                            </div>
                                        </div>
                                        <div className="timeline-item">
                                            <div className="timeline-inner">
                                                <div className="time">02:00pm</div>
                                                <div className="medication">Fluoxetine 20mg</div>
                                            </div>
                                        </div>
                                        <div className="timeline-item">
                                            <div className="timeline-inner">
                                                <div className="time">04:00pm</div>
                                                <div className="medication">Fluoxetine 20mg</div>
                                            </div>
                                        </div>
                                        <div className="timeline-item">
                                            <div className="timeline-inner">
                                                <div className="time">08:00pm</div>
                                                <div className="medication">Fluoxetine 20mg</div>
                                            </div>
                                            <div className="dose">Night dose</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="medical-report">
                                    <div className="medical-report-inner mb-4 mtsm-t">
                                        <div className="section">
                                            <div className="header">
                                                <h2>Recent Medical Reports</h2>
                                                <a href="#">View all</a>
                                            </div>
                                            <div className="report">
                                                <div className="report-item">
                                                    <div className="report-info">
                                                        <h3>Blood Test</h3>
                                                        <p>23 July 2024</p>
                                                    </div>
                                                    <div className="report-icons">
                                                        <span className="icon">
                                                            <img src="/images/eye.svg" alt="" />
                                                        </span>
                                                        <span className="icon">
                                                            <img src="/images/document-download.svg" alt="" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="report-item">
                                                    <div className="report-info">
                                                        <h3>Thyroid Test</h3>
                                                        <p>8 July 2024</p>
                                                    </div>
                                                    <div className="report-icons">
                                                        <span className="icon">
                                                            <img src="/images/eye.svg" alt="" />
                                                        </span>
                                                        <span className="icon">
                                                            <img src="/images/document-download.svg" alt="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medical-report-inner">
                                        <div className="section">
                                            <div className="header">
                                                <h2>Incident Alerts</h2>
                                                <a href="#">View all</a>
                                            </div>
                                            <div className="alert">
                                                <div className="alert-item">
                                                    <h3>Fall Detection</h3>
                                                    <p>Mohan's recent walk resulted in a fall, while it wasn’t serious.
                                                        Further
                                                        investigation would be
                                                        required.</p>
                                                </div>
                                                <div className="alert-item">
                                                    <h3>Missed Medication</h3>
                                                    <p>Mohan recently missed his medication in the evening. The caretaker
                                                        made
                                                        sure within 30 minutes that
                                                        he had taken it then.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
