const url = "https://stageapi.trackerhealth.io";
//  const url = "http://127.0.0.1:8000";
var Service = {
    FamilyLogin:function(item){
        return fetch(`${url}/api/family/login`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(item)
        }).then((result)=>{
            if(result.status === 200)
            {
                return {result:result,state:true}
            }else{
                return {result:result,state:false}
            }
        }).catch((error)=>{
            console.log(error)
        })
    },
    FamilyOtp:function(item)
    {
        return fetch(`${url}/api/family/otp-verify`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(item)
        }).then((result)=>{
            if(result.status === 200){
                return {result:result,state:true}
            }else
            {
                return {result:result,state:false}
            }
        }).catch((error)=>{
            console.log(error)
        })
    }
}
export default Service;